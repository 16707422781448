import { LoadClientToken, PFLitePaymentGrid } from "src/app/classes/entity";
import { HomeLiteViewModel } from "./HomeLiteViewModel";
import _ from 'lodash';
import { FullValuePigService } from "src/app/services/full-value-pig.service";
import { DatePipe } from "@angular/common";

export class ProfitFinderLiteViewModel extends HomeLiteViewModel {

  onLoadPFLitePaymentGrids: Array<PFLitePaymentGrid>;

  static profitFinderLiteViewModel: ProfitFinderLiteViewModel = null;

  private constructor() {
    super();
  }

  static getInstance(): ProfitFinderLiteViewModel {
    if (this.profitFinderLiteViewModel == null) {
      this.profitFinderLiteViewModel = this.createInstance();
    }
    return this.profitFinderLiteViewModel;
  }

  static createInstance(): ProfitFinderLiteViewModel {
    this.profitFinderLiteViewModel = new ProfitFinderLiteViewModel();
    return this.profitFinderLiteViewModel;
  }

  setData(loadClientToken: LoadClientToken, fullValuePigService: FullValuePigService, datePipe: DatePipe) {
    this.fullValuePigService = fullValuePigService;
    this.datePipe = datePipe;
    this.feature = loadClientToken.PFLiteFeature;
    this.onLoadPerformanceParameters = loadClientToken.PerformanceList;
    this.companies = this.feature.AccountList;
    this.groupLitePaymentGrids();
    this.groupPerformanceParameter();
  }

  groupLitePaymentGrids() {
    this.groupedLitePaymentGrids = _.groupBy(this.onLoadPFLitePaymentGrids, 'AccountID');
  }

  companyChanged(companySelected: number) {
    this.companySelected = companySelected;
    this.performanceParameters = this.groupedPerformanceParameters[companySelected];
    this.getPFLitePaymentGrid();
    this.resetDataAfterCompanyChanged();
  }

  getPFLitePaymentGrid() {
    var self = this;
    this.fullValuePigService.getMasterMetadataUnitByAccount(this.companySelected).subscribe(
      response => {
        this.accountMetadataUnit = response;
        this.accountMetadataUnit.forEach(function (value) {
          if (value.DataObject == 'Weight Units') {
            self.getOnLoadPFLitePaymentGrids(value.UnitID);
          }
        });
      },
      errorResp => {
        this.fullValuePigService.throwError(errorResp);
      }
    );
  }

  getOnLoadPFLitePaymentGrids(weightUnitID: number) {
    this.fullValuePigService.getPFLitePaymentGrid(this.companySelected, weightUnitID).subscribe(
      response => {
        this.onLoadPFLitePaymentGrids = response;
        this.groupLitePaymentGrids();
        this.litePaymentGrids = this.groupedLitePaymentGrids[this.companySelected];
      },
      errorResp => {
        this.fullValuePigService.throwError(errorResp);
      }
    );
  }

  resetDataAfterCompanyChanged() {
    this.litePaymentGridSelected = undefined;
    this.performanceParameterSelected = undefined;
  }

  validateProfitFinderLiteData() {
    return (this.startDate != undefined) && (this.endDate != undefined) && (this.companySelected != undefined) && (this.litePaymentGridSelected != undefined) && (this.performanceParameterSelected != undefined);
  }

}
