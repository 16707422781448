import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

// -- Interfaces --
import { NavTab } from 'src/app/classes/entity';

@Component({
    selector: 'app-nav-tab',
    templateUrl: './nav-tab.component.html',
    styleUrls: ['./nav-tab.component.scss']
})
export class NavTabComponent implements OnInit {

    private url: string;
    private action: boolean = false;

    @Input() tabs: Array<NavTab> = [];
    @Input() module: string;

    constructor(
        private router: Router
    ) { }

    ngOnInit() {
        this.url = this.router.url;
        this.setAction();
    }

    setAction() {
        const activeTab = this.tabs.find(t => t.url === this.url);
        if (activeTab) this.action = activeTab.action;
    }

    navigate(tab: NavTab) {
        this.url = tab.url;
        this.setAction();
        this.router.navigate([tab.url]);
    }

}
