import { DatePipe } from "@angular/common";
import { FVPCriteriaValue, LoadClientToken } from "src/app/classes/entity";
import { FullValuePigService } from "src/app/services/full-value-pig.service";
import { HomeViewModel } from "./HomeViewModel";

export class AttritionViewModel extends HomeViewModel {

  static attritionViewModel: AttritionViewModel = null;

  private constructor() {
    super();
  }

  static getInstance(): AttritionViewModel {
    if (this.attritionViewModel == null) {
      this.attritionViewModel = this.createInstance();
    }
    return this.attritionViewModel;
  }

  static createInstance(): AttritionViewModel {
    this.attritionViewModel = new AttritionViewModel();
    return this.attritionViewModel;
  }

  setData(loadClientToken: LoadClientToken, fullValuePigService: FullValuePigService, datePipe: DatePipe) {
    this.fullValuePigService = fullValuePigService;
    this.datePipe = datePipe;
    //this.feature = loadClientToken.AttritionFeature;
    this.onLoadPaymentGrids = loadClientToken.GridMappingList;
    this.criteria = loadClientToken.PFCriteria;
    this.onLoadPerformanceParameters = loadClientToken.PerformanceList;
    this.companies = this.feature.AccountList;
    this.groupPaymentGrids();
    this.groupPerformanceParameter();
  }

  onValueSelect(items: any) {
  }

  onValueDeSelect(items: any) {
  }

  onSelectAll(items: any) {
    this.valueSelectedItems = items;
  }

  setPaymentGridIds() {
    var self = this;
    this.paymentGridIDs = [];
    this.onLoadPaymentGrids.forEach(function (paymentGrid) {
      self.fvpPackerMatrix.forEach(function (packerMatrix) {
        if ((paymentGrid.companyID == self.companySelected) && (paymentGrid.packerCode == packerMatrix.Packer.Code) && (paymentGrid.marketContract == packerMatrix.MarketContract)) {
          self.paymentGridIDs.push(paymentGrid.paymentGridID);
        }
      });
    });
  }

  validateAttritionData() {
    return (this.startDate != undefined) && (this.endDate != undefined) && (this.companySelected != undefined) && (this.criteriaSelected != undefined) && (this.valueSelectedItems.length > 0) && (this.performanceParameterSelected != undefined);
  }

  getFVPCriteriaValue(): FVPCriteriaValue {
    this.criteriaValue = {
      PaymentGridIDs: this.paymentGridIDs,
      Values: this.getValueSelected(),
      MaxRecord: undefined
    };
    return this.criteriaValue;
  }

  resetDataAfterCriteriaChanged() {
    this.performanceParameterSelected = undefined;
  }

}
