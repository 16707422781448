import { ISwineCarcassLot, SwineCarcassLot } from "./swine-carcass-lot";
import { ISwineCloseOut, SwineCloseOut } from "./swine-close-out";
import { ISwineMortality, SwineMortality } from "./swine-mortality";

export class SwineCarcass {

    CloseOutData: SwineCloseOut;
    MortalityData: Array<SwineMortality>;
    CarcassLotData: Array<SwineCarcassLot>;

    constructor() { }

}

export interface ISwineCarcass {
    A: ISwineCloseOut;
    B: Array<ISwineMortality>;
    C: Array<ISwineCarcassLot>;
}