import { AccountMetadataUnit } from "./AccountMetadataUnit";
import { AccountName } from "./AccountName";
import { ColumnChatInstance } from "./ColumnChatInstance";
import { MatrixName } from "./Enum";
import { GrowthCurvesCore } from "./GrowthCurvesCore";
import { LitePaymentGrid } from "./LitePaymentGrid";
import { MatrixGrid } from "./MatrixGrid";
import { MatrixOptimizedValue } from "./MatrixOptimizedValue";
import { MatrixUnitValue } from "./MatrixUnitValue";
import { OMWLiteTitle } from "./OMWLiteTitle";
import { OMWMatrixExtend } from "./OMWMatrixExtend";
import { PerformanceAdjustment } from "./PerformanceAdjustment";
import { PerformanceFeedProgram } from "./PerformanceFeedProgram";
import { PerformanceGrowthADFICalculation } from "./PerformanceGrowthADFI";
import { PerformanceParameter } from "./PerformanceParameter";

export class OMWLiteSession {

    private static instance: OMWLiteSession;

    CurvesCore: GrowthCurvesCore;
    FeatureID: number;
    IsValid: boolean;
    UnitValue: MatrixUnitValue;
    AccountCollection: AccountName[];
    WeightUnit: AccountMetadataUnit;
    CurrencyUnit: AccountMetadataUnit;
    DateRangeMin: any;
    DateRangeMax: any;
    SelectCompany: AccountName;
    SelectRecord: number;
    GetDataDate: string;
    SelectPerformancePara: PerformanceParameter;
    SelectedPaymentGrid: LitePaymentGrid;
    CurrentMatrix: MatrixName;
    FeedProgramCollection: PerformanceFeedProgram[];
    GrowthADFICollection: PerformanceGrowthADFICalculation[];
    AdjustmentCollection: PerformanceAdjustment[];
    MatrixGridCollection: MatrixGrid[];
    MatrixExtend: OMWMatrixExtend;
    OptimizedValue: MatrixOptimizedValue;
    ResetOptimizedValue: MatrixOptimizedValue;
    MOFCPopulationOptimal: ColumnChatInstance[];
    MOFCPopulation: ColumnChatInstance[];
    TitleKey: OMWLiteTitle;

    public static REDUCE_STDEV_OF_MARKETING_BY: number = 0;

    public static getInstance(): OMWLiteSession {
        if(!OMWLiteSession.instance) {
            OMWLiteSession.instance = new  OMWLiteSession();
        }

        return OMWLiteSession.instance;
    }

}