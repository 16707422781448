import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberPipe'
})
export class NumPipeComponent implements PipeTransform {
    transform(value: string): any {
        let retNumber = Number(value);
        console.log("numberPipe=" + value);
        return isNaN(retNumber) ? 0 : retNumber;
    }
}
