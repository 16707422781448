import { Injectable } from "@angular/core";
import { request } from "http";
import { encode } from "punycode";
import { environment } from "src/environments/environment";

@Injectable()
export class AbstractService {

	public apiPath = "";
	public userGuideUrlPOY ="";
	public userGuideUrlAY ="";
	public userGuideUrlPFY ="";

	constructor() {
		this.getEnvironment();
	}	
	
	async getEnvironment(): Promise<string> {

		if (document.URL.search('localhost') !== -1) {
			this.apiPath = environment.localData;
		} else if (location.host.match(/^fvp[.]{1}swine[.]{1}solutions-d[.]{1}elanco.com/)||location.host.match(/^fvp-swine-eks-solutions-d[.]{1}azurewebsites.net/)) {
			this.apiPath = environment.devData;
		} else if (location.host.match(/^fvp[.]{1}swine[.]{1}solutions-q[.]{1}elanco.com/)||location.host.match(/^fvp-swine-eks-solutions-q[.]{1}azurewebsites.net/)) {
			this.apiPath = environment.qaData;
		} else {
			this.apiPath = environment.prodData;
		}

			this.userGuideUrlPOY = this.apiPath+"getPOLiteUserGuide";
			this.userGuideUrlPFY = this.apiPath+"getPFLiteUserGuide";
			this.userGuideUrlAY = this.apiPath+"getAttrLiteUserGuid";
			return this.apiPath;
	}
}