export interface Datum {
    A: number;
    B: number;
    C: number;
    D: string;
    E: number;
    F: number;
    G: string;
    H: number;
    I: number;
    J: number;
}

export class OMWLiteData {
    Data: Datum[];
    BasePrice: number;

    constructor() { }

    setData(omwLiteData: OMWLiteData) {
        this.Data = omwLiteData.Data;
        this.BasePrice = omwLiteData.BasePrice;
    }
}

export class OmwLiteData{
    static Data: Datum[];
    static BasePrice: number;

    constructor() { }
}