import { Injectable } from '@angular/core';

@Injectable()
export class FVPDataMappingService {

    // Getting map data service
    getMapData() {
        const response: Array<object> = [
            {
                id: 1,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 2,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 3,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 4,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 5,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 6,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 7,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 8,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 9,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 10,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            }
        ];

        return response;
    }

    // Getting submitted data service
    getSubmittedData() {
        const response: Array<object> = [
            {
                id: 1,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 2,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 3,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 4,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 5,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 6,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 7,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 8,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 9,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            },
            {
                id: 10,
                lot: '',
                date: '4/2/2017',
                group_name: '',
                site: '',
                producer: 'F077',
                packer: '',
                head_count: '2',
                total_weight: '',
                file_type: 'Mortality'
            }
        ];

        return response;
    }
}
