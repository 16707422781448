import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FullValuePigService } from 'src/app/services/full-value-pig.service';

@Component({
    selector: 'app-data-filters',
    templateUrl: './datafilter.component.html',
    styleUrls: ['./datafilter.component.scss']
})
export class DataFilterComponent implements OnInit {

    @Input() dataFilterInput: any;

    dropdownSettings: IDropdownSettings = {};

    dropdownData: any[] = [
        { name: 'years', display: 'Year', values: [], selectedValues: [], ready: false },
        { name: 'producers', display: 'Producer', values: [], selectedValues: [], ready: false },
        { name: 'months', display: 'Month', values: [], selectedValues: [], ready: false },
        { name: 'sites', display: 'Site', values: [], selectedValues: [], ready: false },
        { name: 'groupNames', display: 'Group Name', values: [], selectedValues: [], ready: false },
        { name: 'barns', display: 'Barn', values: [], selectedValues: [], ready: false },
        { name: 'pigFlows', display: 'Pig Flow', values: [], selectedValues: [], ready: false },
        { name: 'barnCuts', display: 'Barn Cut', values: [], selectedValues: [], ready: false },
        { name: 'podNames', display: 'Pod Name', values: [], selectedValues: [], ready: false },
        { name: 'tattoos', display: 'Tattoo', values: [], selectedValues: [], ready: false },
        { name: 'businessUnits', display: 'Business Unit', values: [], selectedValues: [], ready: false },
        { name: 'productionTypes', display: 'Production Type', values: [], selectedValues: [], ready: false },
        { name: 'fieldMans', display: 'Field Man', values: [], selectedValues: [], ready: false },
        { name: 'healthPlans', display: 'Health Plan', values: [], selectedValues: [], ready: false },
        { name: 'feedSources', display: 'Feed Source', values: [], selectedValues: [], ready: false },
        { name: 'healthStatuses', display: 'Health Status', values: [], selectedValues: [], ready: false },
        { name: 'pigSources', display: 'Pig Source', values: [], selectedValues: [], ready: false },
        { name: 'feedMills', display: 'Feed Mill', values: [], selectedValues: [], ready: false },
        { name: 'pigSourceTypes', display: 'Pig Source Type', values: [], selectedValues: [], ready: false }
    ];

    constructor(private router: Router, private fullValuePigService: FullValuePigService, private translate: TranslateService) {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            // this.dropdownSettings.selectAllText = this.translateSelectAll('Select All');
            this.dropDownSetting();
        });
    }

    ngOnInit() {
        this.dropDownSetting();
        this.loadData();
    }

    dropDownSetting() {
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'value',
            textField: 'name',
            selectAllText: this.translateSelectAll('Select All'),//'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: false
        };
    }

    setDropdownValues(data, selectedValues, index): void {
        if (data.length > 0) {
            this.dropdownData[index].values = data;
            this.dropdownData[index].selectedValues = selectedValues;
            this.dropdownData[index].ready = true;
        } else {
            this.dropdownData[index].ready = false;
        }
    }

    loadData(): void {
        this.dropdownData.forEach((datum, index) => {
            let filterName = datum.name + 'Selected';
            this.setDropdownValues(this.dataFilterInput.filter[datum.name], this.dataFilterInput.filter[filterName], index);
        });
    }

    handleFilters(): void {
        let self = this;
        this.dataFilterInput.filter.resetSelectedValues();
        for (var i = 0; i < this.dropdownData.length; i++) {
            let datum = this.dropdownData[i];
            if (datum.ready && datum.selectedValues.length > 0) {
                this.fullValuePigService.addDataFilterIcon = true;
                break;
            }
            else {
                this.fullValuePigService.addDataFilterIcon = false;
            }
        }
        this.dropdownData.forEach((datum) => {
            if (datum.ready && datum.selectedValues.length > 0) {
                let filterName = datum.name + 'Selected';
                this.dataFilterInput.filter[filterName] = datum.selectedValues;
            }
        });
        this.dataFilterInput.calculate.FilterCarcassData(this.router);
        var url: string = this.router.url;
        this.fullValuePigService.urlPath = url.split('/')[3];
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([url]);
        })
    }

    translateSelectAll(message) {
        var selectAll = message;
        this.translate.stream(message).subscribe((text) => {
            selectAll = text;
        });
        return selectAll;
    }
};