export class SwineCloseOut {

    DMID: number;
    GroupName: string;
    GroupEndDate: string;
    StartWeight: number;
    EndWeight: number;
    FeedCostPerUnitGain: number;
    LastFinishingDietCost: number;
    CarcassBasePrice: number;
    PigsPlaced: number;
    FinisherMortality: number;
    Culls: number;
    DOA: number;
    NonAmbulatory: number;
    ProducerName: string;
    SiteName: string;
    PigFlowName: string;
    GroupStartDate: string;
    CullWeight: number;
    GroupDays: number;
    ProductionType: string;
    PodName: string;
    BusinessUnitName: string;
    Fieldman: string;
    FeedSource: string;
    PigSource: string;
    PigSourceType: string;
    HealthPlan: string;
    HealthStatus: string;
    FeedMill: string;

    constructor() { }

}

export interface ISwineCloseOut {
    A: number;
    AA: string;
    AB: string;
    AC: number;
    B: string;
    C: string;
    D: number;
    E: number;
    F: number;
    G: number;
    H: number;
    I: number;
    J: number;
    K: number;
    L: number;
    M: number;
    N: string;
    O: string;
    P: string;
    Q: string;
    R: number;
    S: string;
    T: string;
    U: string;
    V: string;
    W: string;
    X: string;
    Y: string;
    Z: string;
}