import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { DataFilters } from 'src/app/classes/entity/data-filter';



@Injectable()
export class DataFilterService {

    filtersAvailable: EventEmitter<DataFilters> = new EventEmitter<DataFilters>();

    years: any = [
        { name: '2019', value: '2019' },
        { name: '2020', value: '2020' }
    ];

    months: any = [
        { name: 'January', value: 'January' },
        { name: 'February', value: 'February' },
        { name: 'March', value: 'March' },
        { name: 'April', value: 'April' },
        { name: 'May', value: 'May' },
        { name: 'June', value: 'June' },
        { name: 'July', value: 'July' },
        { name: 'August', value: 'August' },
        { name: 'September', value: 'September' },
        { name: 'October', value: 'October' },
        { name: 'November', value: 'November' },
        { name: 'December', value: 'December' }
    ];

    producers: any = [
        { name: 'Prod1', value: 'Hilltop' },
        { name: 'Prod2', value: 'capital' }
    ];

    sites: any = [
        { name: 'Bald Hilltop', value: 'Hilltop' },
        { name: 'Tuskdale', value: 'capital' }
    ];

    groupNames: any = [
        { name: 'GN1', value: 'Hilltop' },
        { name: 'GN2', value: 'capital' }
    ];

    barns: any = [
        { name: 'B1', value: 'Hilltop' },
        { name: 'B2', value: 'capital' }
    ];

    pigFlows: any = [
        { name: 'PF1', value: 'Hilltop' },
        { name: 'PF2', value: 'capital' }
    ];

    barnCuts: any = [
        { name: 'BC1', value: 'Hilltop' },
        { name: 'BC2', value: 'capital' }
    ];

    podNames: any = [
        { name: 'POD1', value: 'Hilltop' },
        { name: 'POD2', value: 'capital' }
    ];

    tattoos: any = [
        { name: 'T1', value: 'Hilltop' },
        { name: 'T2', value: 'capital' }
    ];

    businessUnits: any = [
        { name: 'BU1', value: 'Hilltop' },
        { name: 'BU2', value: 'capital' }
    ];

    productionTypes: any = [
        { name: 'PT1', value: 'Hilltop' },
        { name: 'PT2', value: 'capital' }
    ];

    fieldMans: any = [
        { name: 'FM1', value: 'Hilltop' },
        { name: 'FM2', value: 'capital' }
    ];

    healthPlans: any = [
        { name: 'HP1', value: 'Hilltop' },
        { name: 'HP2', value: 'capital' }
    ];

    feedSources: any = [
        { name: 'FS1', value: 'Hilltop' },
        { name: 'FS2', value: 'capital' }
    ];

    healthStatuses: any = [
        { name: 'HS1', value: 'Hilltop' },
        { name: 'HS2', value: 'capital' }
    ];

    pigSources: any = [
        { name: 'PS1', value: 'Hilltop' },
        { name: 'PS2', value: 'capital' }
    ];

    feedMills: any = [
        { name: 'FEED1', value: 'Hilltop' },
        { name: 'FEED2', value: 'capital' }
    ];

    pigSourceTypes: any = [
        { name: 'PST1', value: 'Hilltop' },
        { name: 'PST2', value: 'capital' }
    ];

    getYears(): Observable<any> {
        return Observable.of(this.years);
    }

    getMonths(): Observable<any> {
        return Observable.of(this.months);
    }

    getProducers(): Observable<any> {
        return Observable.of(this.producers);
    }

    getSites(): Observable<any> {
        return Observable.of(this.sites);
    }

    getGroupNames(): Observable<any> {
        return Observable.of(this.groupNames);
    }

    getBarns(): Observable<any> {
        return Observable.of(this.barns);
    }

    getPigFlows(): Observable<any> {
        return Observable.of(this.pigFlows);
    }

    getBarnCuts(): Observable<any> {
        return Observable.of(this.barnCuts);
    }

    getPodNames(): Observable<any> {
        return Observable.of(this.podNames);
    }

    getTattoos(): Observable<any> {
        return Observable.of(this.tattoos);
    }

    getBusinessUnits(): Observable<any> {
        return Observable.of(this.businessUnits);
    }

    getProductionTypes(): Observable<any> {
        return Observable.of(this.productionTypes);
    }

    getFieldMan(): Observable<any> {
        return Observable.of(this.fieldMans);
    }

    getHealthPlans(): Observable<any> {
        return Observable.of(this.healthPlans);
    }

    getFeedSources(): Observable<any> {
        return Observable.of(this.feedSources);
    }

    getHealthStatuses(): Observable<any> {
        return Observable.of(this.healthStatuses);
    }

    getPigSources(): Observable<any> {
        return Observable.of(this.pigSources);
    }

    getFeedMills(): Observable<any> {
        return Observable.of(this.feedMills);
    }

    getPigSourceTypes(): Observable<any> {
        return Observable.of(this.pigSourceTypes);
    }

    setAvailableFilters(filters: DataFilters): void {
        this.filtersAvailable.emit(filters);
    }
}