export interface IAdjustADFI {
    AdjustmentID: number;
    AdjustmentName: string;
    WeekStart: number;
    WeekEnd: number;
    AdgAdjustment: number;
    AdfiAdjustment: number;
    IsDisable: boolean;
}

export class AdjustList {
    AdjustADFI!: IAdjustADFI[];

    constructor(){}

    setData(adjustADFI: AdjustList ){
        this.AdjustADFI = adjustADFI.AdjustADFI;
    }
}