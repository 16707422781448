import { DatePipe } from "@angular/common";
import { LitePaymentGrid, AccountList, Feature, PerformanceList, AccountMetadataUnit, LiteCriteriaKey } from 'src/app/classes/entity'
import { FullValuePigService } from "src/app/services/full-value-pig.service";
import _ from 'lodash';

export abstract class HomeLiteViewModel {

  feature: Feature;

  litePaymentGrids: Array<LitePaymentGrid> = [];
  onLoadPerformanceParameters: Array<PerformanceList> = [];
  performanceParameters: Array<PerformanceList> = [];

  groupedLitePaymentGrids: any;

  litePaymentGridSelected: number;

  groupedPerformanceParameters: any;

  startDate: Date;
  endDate: Date;

  companies: Array<AccountList> = [];

  companySelected: number;
  performanceParameterSelected: number;

  liteCriteriaKey: LiteCriteriaKey;
  accountMetadataUnit: Array<AccountMetadataUnit> = [];
  weightUnitId: number;
  currencyUnitId: number;
  weightFieldUnit: string;
  currencyFieldUnit: string;

  isValidCompany: boolean = false;

  getDataDate: string;
  getDataDateVisibility: boolean = false;

  fullValuePigService: FullValuePigService;
  datePipe: DatePipe;

  groupPerformanceParameter() {
    this.groupedPerformanceParameters = _.groupBy(this.onLoadPerformanceParameters, 'accountId');
  }

  litePaymentGridChanged(litePaymentGridSelected) {
    this.litePaymentGridSelected = litePaymentGridSelected;
  }

  performanceParameterChanged(performanceParameterSelected) {
    this.performanceParameterSelected = performanceParameterSelected;
  }

  validateStartDate() {
    if (new Date(this.startDate) > new Date()) {
      this.startDate = undefined;
      return { status: false, message: 'Start date can not be greater than end date.' };
    }
    if ((this.endDate != undefined) && (new Date(this.startDate) >= new Date(this.endDate))) {
      this.startDate = undefined;
      return { status: false, message: 'Start Date cannot be equal or greater than end date' };
    }
    return { status: true, message: 'Valid start date' };
  }

  validateEndDate() {
    if (new Date(this.endDate) > new Date()) {
      this.endDate = undefined;
      return { status: false, message: 'End Date cannot be greater than today date' };
    }
    if ((this.startDate != undefined) && (new Date(this.startDate) > new Date(this.endDate))) {
      this.endDate = undefined;
      return { status: false, message: 'End Date cannot be greater than today date' };
    }
    return { status: true, message: 'Valid start date' };
  }

  getLiteCriteriaKey() {
    this.liteCriteriaKey = {
      CompanyID: this.companySelected,
      DateStart: this.datePipe.transform(this.startDate, 'yyyy-MM-ddTHH:mm:ss'),
      DateEnd: this.datePipe.transform(this.endDate, 'yyyy-MM-ddTHH:mm:ss'),
    };
    return this.liteCriteriaKey;
  }

}
