import { Injectable } from "@angular/core";
import { ICompany } from "src/app/classes/entity";
import { PerformanceParameter } from "src/app/classes/entity/performace";
import { AccountList } from "src/app/classes/entity";

@Injectable()
export class UserInputService {

    selectedCompany: ICompany;
    selectedParameter: PerformanceParameter;
    companies: ICompany[] = [];
    performanceParameters: PerformanceParameter[] = [];
    liteCompanies: Array<AccountList> = [];
    groupedPerformanceParameters = [];


}