import { DatePipe } from "@angular/common";
import { LitePaymentGrid, AccountList, Feature, PerformanceList, AccountMetadataUnit, LiteCriteriaKey, LoadClientToken, OMWLiteCriteriaValue } from 'src/app/classes/entity'
import { FullValuePigService } from "src/app/services/full-value-pig.service";
import { HomeLiteViewModel } from "./HomeLiteViewModel";
import _ from 'lodash';
declare var $: any;
export class ProfitOptimizerLiteViewModel extends HomeLiteViewModel {

  onLoadLitePaymentGrids: Array<LitePaymentGrid> = [];

  numberOfRecordSelected: number;

  omwLiteCriteriaValue: OMWLiteCriteriaValue;

  static profitOptimizerLiteViewModel: ProfitOptimizerLiteViewModel = null;

  private constructor() {
    super();
  }

  static getInstance(): ProfitOptimizerLiteViewModel {
    if (this.profitOptimizerLiteViewModel == null) {
      this.profitOptimizerLiteViewModel = this.createInstance();
    }
    return this.profitOptimizerLiteViewModel;
  }

  static createInstance(): ProfitOptimizerLiteViewModel {
    this.profitOptimizerLiteViewModel = new ProfitOptimizerLiteViewModel();
    return this.profitOptimizerLiteViewModel;
  }

  setData(loadClientToken: LoadClientToken, fullValuePigService: FullValuePigService, datePipe: DatePipe) {
    this.fullValuePigService = fullValuePigService;
    this.datePipe = datePipe;
    this.feature = loadClientToken.OMWLiteFeature;
    this.onLoadLitePaymentGrids = loadClientToken.LitePaymentGrids;
    this.onLoadPerformanceParameters = loadClientToken.PerformanceList;
    this.companies = this.feature.AccountList;
    this.groupLitePaymentGrids();
    this.groupPerformanceParameter();
  }

  groupLitePaymentGrids() {
    this.groupedLitePaymentGrids = _.groupBy(this.onLoadLitePaymentGrids, 'AccountID');
  }

  companyChanged(companySelected: number) {
    this.companySelected = companySelected;
    this.performanceParameters = this.groupedPerformanceParameters[companySelected];
    this.litePaymentGrids = this.groupedLitePaymentGrids[companySelected];
    this.resetDataAfterCompanyChanged();
  }

  numberOfRecordChanged(numberOfRecordSelected) {
    this.numberOfRecordSelected = numberOfRecordSelected;
  }

  resetDataAfterCompanyChanged() {
    this.litePaymentGridSelected = undefined;
    this.performanceParameterSelected = undefined;
    this.numberOfRecordSelected = undefined;
  }

  validateProfitOptimizerLiteData() {
    return (this.startDate != undefined) && (this.endDate != undefined) && (this.companySelected != undefined) && (this.litePaymentGridSelected != undefined) && (this.performanceParameterSelected != undefined) && (this.numberOfRecordSelected != undefined);
  }

  getOMWLiteCriteriaValue() {
    this.omwLiteCriteriaValue = {
      PaymentGridID: this.litePaymentGridSelected,
      MaxRecord: this.numberOfRecordSelected
    };
    return this.omwLiteCriteriaValue;
  }

}
