import { GridCoord } from "./payment-grid";

export class SwineCarcassCore {

    CarcassWeight: number;
    CarcassLean: number;
    Grade: string;
    CarcassBackfat: number;
    CarcassLoinDepth: number;
    SexType: string;
    Coord: GridCoord;
    HasBackfat: boolean;
    HasLoinDepth: boolean;
    HasCarcassLean: boolean;

    constructor() { }

}