import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

// -- Application Modules --
import { ServiceModule } from '../services/service.module';

// -- Components --
import { FooterComponent } from './footer/footer.component';
import { NavTabComponent } from './nav-tab/nav-tab.component';
import { FvpModalComponent } from './fvp-modal/fvp-modal.component';
import { NumPipeComponent } from './num-pipe/num-pipe.component';

import { DataSummaryComponent } from '../portal/shared/data-summary/data-summary.component';
import { DataFilterComponent } from '../portal/shared/datafilter/datafilter.component';
import { HomeComponent } from '../portal/shared/home/home.component';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { MaterialModule } from './material-module';
import * as moment from 'moment';

@NgModule({
  declarations: [
    FooterComponent,
    NavTabComponent,
    FvpModalComponent,
    NumPipeComponent,
    DataSummaryComponent,
    DataFilterComponent,
    HomeComponent,
    LoaderComponent
  ],
  imports: [
    // Angular modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,

    // Application Modules
    ServiceModule,
    RouterModule,
    HttpClientModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MaterialModule

  ],
  exports: [
    // Angular modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    TranslateModule,

    // Application Modules
    ServiceModule,

    // Components
    FooterComponent,
    NavTabComponent,
    FvpModalComponent,
    NumPipeComponent,
    DataSummaryComponent,
    DataFilterComponent,
    HomeComponent,
    LoaderComponent,
    MaterialModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class SharedModule { }

export class CustomDateAdapter extends MomentDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    return moment.weekdaysShort();
  }
  // getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
  //   return moment.months();
  // }
}
export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMMM YYYY',
  }
};
