import { ItemCode } from "./base";
import { ISwineCarcassDetail, SwineCarcassDetail } from "./swine-carcass-detail";

export class SwineCarcassLot {

    CarcassLotID: number;
    Barn: string;
    BarnCut: string;
    Tatoo: string;
    Packer: ItemCode;
    DetailData: Array<SwineCarcassDetail>;
    MarketContract: string;
    KillDate: string;

    constructor() { }

}

export interface ISwineCarcassLot {
    A: string;
    B: string;
    C: string;
    D: string;
    E: string;
    F: Array<ISwineCarcassDetail>
    G: string;
    H: number;
    I: string;
}