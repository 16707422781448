import { AccountName } from "src/app/classes/entity";
import { AttritionCalculate } from "./AttritionCalculate";
import { ItemCode } from "./ItemCode";
import { ItemName } from "./ItemName";
import { PerformanceFeedProgram } from "./PerformanceFeedProgram";
import { PerformanceGrowthADFI } from "./PerformanceGrowthADFI";

import { PerformanceParameter } from "./PerformanceParameter";
import { ProfitFinderCalculate } from "./ProfitFinderCalculate";

export class PFSession {

    private static instance: PFSession;

    IsValid: boolean;
    AccountCollection: AccountName[];
    Calculate: ProfitFinderCalculate;
    AttritionCalculate: AttritionCalculate;
    DateRangeMax: any;
    DateRangeMin: any;
    SelectCompany: AccountName;
    SelectCriteria: string;
    SelectValues: ItemCode[];
    AllValues: ItemCode[];
    AllPacker: ItemName[];
    SelectPacker: ItemName;
    SelectRecord: number;
    GetDataDate: any;
    SelectPerformance: PerformanceParameter;
    FeedProgramList: PerformanceFeedProgram[];
    GrowthADFIList: PerformanceGrowthADFI[];

    public static getInstance(): PFSession {
        if (!PFSession.instance) {
            PFSession.instance = new PFSession();
        }

        return PFSession.instance;
    }

}