export interface IPaymentGridDetail {
    ID: number;
    PaymentGridID: number;
    MinColumn: number;
    MaxColumn: number;
    MinRow: number;
    MaxRow: number;
    MatrixValue: number;
    ColumnIndex: number;
    RowIndex: number;
    RowHeader: string;
}

export class OMWLitePaymentGridDetail {
    PaymentGridDetail!: IPaymentGridDetail[];

    constructor() { }

    setData(paymentGridDetail) {
        this.PaymentGridDetail = paymentGridDetail;
    }
}
