import { DatePipe } from "@angular/common";
import { LoadClientToken } from "src/app/classes/entity";
import { FullValuePigService } from "src/app/services/full-value-pig.service";
import { HomeLiteViewModel } from "./HomeLiteViewModel";

export class AttritionLiteViewModel extends HomeLiteViewModel {

  static attritionLiteViewModel: AttritionLiteViewModel = null;

  private constructor() {
    super();
  }

  static getInstance(): AttritionLiteViewModel {
    if (this.attritionLiteViewModel == null) {
      this.attritionLiteViewModel = this.createInstance();
    }
    return this.attritionLiteViewModel;
  }

  static createInstance(): AttritionLiteViewModel {
    this.attritionLiteViewModel = new AttritionLiteViewModel();
    return this.attritionLiteViewModel;
  }

  setData(loadClientToken: LoadClientToken, fullValuePigService: FullValuePigService, datePipe: DatePipe) {
    this.fullValuePigService = fullValuePigService;
    this.datePipe = datePipe;
    this.feature = loadClientToken.AttritionLiteFeature;
    this.onLoadPerformanceParameters = loadClientToken.PerformanceList;
    this.companies = this.feature.AccountList;
    this.groupPerformanceParameter();
  }

  companyChanged(companySelected: number) {
    this.companySelected = companySelected;
    this.performanceParameters = this.groupedPerformanceParameters[companySelected];
    this.resetDataAfterCompanyChanged();
  }

  resetDataAfterCompanyChanged() {
    this.performanceParameterSelected = undefined;
  }

  validateAttritionLiteData() {
    return (this.startDate != undefined) && (this.endDate != undefined) && (this.companySelected != undefined) && (this.performanceParameterSelected != undefined);
  }

}
