import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountMetadataUnit, AccountMetadataUnits, criteriaData, FVPCriteriaKey, FVPCriteriaValue, FVPPacker, FVPPackers, ItemCode, LiteCriteriaKey, LoadClientToken, OMWLiteCriteriaValue, PFLitePaymentGrid, PFLitePaymentGrids } from 'src/app/classes/entity';
import { Performance, PaymentGrid, TSwineCarcassDetail } from 'src/app/classes/entity/profit-optimizer';
import { PFPaymentGrid, PFData, BlockData, PFPerformance, PFBlockedData } from 'src/app/classes/entity/profit-finder';
import { ISwineCarcass } from "src/app/classes/entity";

import { PFLitePaymentGridDetail } from 'src/app/classes/entity/profit-finder-y';
import { FeedProgram, PerformanceFeedProgram } from 'src/app/classes/entity/performace';
import { OMWLiteBatchData, OMWLitePaymentGridDetail, OMWLiteData, AdjustList } from 'src/app/classes/entity/profit-optimizer-y';
import { IPerformanceGrowthADFI, PerformanceGrowthADFI } from 'src/app/classes/entity/performace';
import { AbstractService } from './abstract.service';
import { IFVPPaymentGrid } from 'src/app/classes/entity/payment-grid';
import { IPFData } from 'src/app/classes/entity/profit-finder';
import { FVPPerformance } from 'src/app/classes/entity/performace';
import { ExportPPT } from 'src/app/classes/entity';
import { Formular } from 'src/app/portal/profit-optimizer/Calculations/classes/Formular';
import { OptimalCase } from 'src/app/portal/profit-optimizer/market-weight-optimization/viewModel/OptimalCase';
import { OptimalCaseY } from 'src/app/portal/profit-optimizer-y/market-weight-optimization/view-model/OptimalCase';
import { SignPointParameter } from 'src/app/portal/profit-optimizer-y/po-y-calculations/classes/SignPointParameter';

@Injectable({
  providedIn: 'root'
})

export class FullValuePigService extends AbstractService {

  loadClientToken: LoadClientToken;
  tSwineCarcassDetail: TSwineCarcassDetail;
  paymentGrid: PaymentGrid;
  performance: Performance;
  pfData: PFData;
  pfPaymentGrid: PFPaymentGrid;
  pfPerformance: PFPerformance;
  pfBlockedData: PFBlockedData;
  feedProgram: FeedProgram;
  growthADFI: PerformanceGrowthADFI;
  omwLiteData: OMWLiteData;
  omwLiteBatchData: OMWLiteBatchData;
  omwLitePaymentGridDetail: OMWLitePaymentGridDetail;
  omwLiteFeedProgram: FeedProgram;
  omwLiteGrowthADFI: PerformanceGrowthADFI;
  omwLiteAdjustADFI: AdjustList;
  urlPath: string = '';

  // -- Menu Items --
  menuItems: Array<object> = [

    // User Inputs
    {
      title: 'User Inputs',
      title_id: 'user-inputs',
      item_id: 'user-inputs-items',
      url: 'user-inputs',
      hasAccess: true,
      submenu: [
        {
          name: 'Growth Parameters',
          url: '/user-inputs/growth-parameters'
        },
        {
          name: 'Assumptions',
          url: '/user-inputs/assumptions'
        }
      ]
    },
    // Profit Optimizer
    {
      title: 'Profit Optimizer',
      title_id: 'profit-optimizer',
      item_id: 'profit-optimizer-items',
      url: 'profit-optimizer',
      hasAccess: true,
      submenu: [
        {
          name: 'Home',
          url: '/profit-optimizer/home'
        },
        {
          name: 'Market Weight Optimization',
          url: '/profit-optimizer/market-weight-optimization'
        },
        {
          name: 'Marketed Animals in Matrix',
          url: '/profit-optimizer/marketed-animals-in-matrix'
        },
        {
          name: 'MOFC Summary',
          // url: '/profit-optimizer/mofc-summary'
          url: '/profit-optimizer/mofc-summary/marketed-mofc-by-pig'
        },
        {
          name: 'Growth and FI Curves',
          url: '/profit-optimizer/growth-and-fi-curves'
        },
        {
          name: 'Export PowerPoint',
          url: '/profit-optimizer/export-powerpoint'
        }
      ]
    },

    // Profit Optimizer Y
    {
      title: 'Profit Optimizer Y',
      title_id: 'profit-optimizer-y',
      item_id: 'profit-optimizer-y-items',
      url: 'profit-optimizer-y',
      hasAccess: true,
      submenu: [
        {
          name: 'Home',
          url: '/profit-optimizer-y/home'
        },
        {
          name: 'Market Weight Optimization',
          url: '/profit-optimizer-y/market-weight-optimization'
        },
        {
          name: 'Marketed Animals in Matrix',
          url: '/profit-optimizer-y/marketed-animals-in-matrix'
        },
        {
          name: 'MOFC Summary',
          url: '/profit-optimizer-y/mofc-summary'
        },
        {
          name: 'Growth and FI Curves',
          url: '/profit-optimizer-y/growth-and-fi-curves'
        },
        {
          name: 'Export PowerPoint',
          url: '/profit-optimizer-y/export-powerpoint'
        },
        {
          name: 'User Guide',
          url: '/profit-optimizer-y/profit-optimizer-y-user-guide'
        }
      ]
    },

    // Profit Finder
    {
      title: 'Profit Finder',
      title_id: 'profit-finder',
      item_id: 'profit-finder-items',
      url: 'profit-finder',
      hasAccess: true,
      submenu: [
        {
          name: 'Home',
          url: '/profit-finder/home'
        },
        {
          name: 'Data Summary',
          url: '/profit-finder/data-summary'
        },
        {
          name: 'Packer Matrix',
          url: '/profit-finder/packer-matrix'
        },
        {
          name: 'Margin Over Feed by Cell',
          url: '/profit-finder/margin-over-feed-by-cell'
        },
        {
          name: 'Marketed Animals in Matrix',
          url: '/profit-finder/marketed-animals-in-matrix'
        },
        {
          name: 'Quantify FVP & Non FVP',
          url: '/profit-finder/quantifyfvp-and-nonfvp'
        },
        {
          name: 'Lost Opportunity Cost',
          url: '/profit-finder/lost-opportunity-cost'
        },
        {
          name: 'FVP Summary',
          url: '/profit-finder/summary'
        },
        {
          name: 'Export PowerPoint',
          url: '/profit-finder/export-powerpoint'
        }
      ]
    },

    // Profit Finder Y
    {
      title: 'Profit Finder Y',
      title_id: 'profit-finder-y',
      item_id: 'profit-finder-y-items',
      url: 'profit-finder-y',
      hasAccess: true,
      submenu: [
        {
          name: 'Home',
          url: '/profit-finder-y/home'
        },
        {
          name: 'Payment Grid',
          url: '/profit-finder-y/payment-grid-y'
        },
        {
          name: 'Data Summary',
          url: '/profit-finder-y/data-summary'
        },
        {
          name: 'Margin Over Feed by Cell',
          url: '/profit-finder-y/margin-over-feed-by-cell'
        },
        {
          name: 'Marketed Animals in Matrix',
          url: '/profit-finder-y/marketed-animals-in-matrix'
        },
        {
          name: 'Quantify FVP & Non FVP',
          url: '/profit-finder-y/quantify-fvp-nonfvp'
        },
        {
          name: 'Lost Opportunity Cost',
          url: '/profit-finder-y/lost-opportunity-cost'
        },
        {
          name: 'FVP Summary',
          url: '/profit-finder-y/summary'
        },
        {
          name: 'Export PowerPoint',
          url: '/profit-finder-y/export-powerpoint'
        },
        {
          name: 'User Guide',
          url: '/profit-finder-y/profit-finder-y-user-guide'
        }
      ]
    },
  ];



  linColor = "#000000";
  signColor = "#FF0000";
  strColor = "#000000";
  addDataFilterIcon: boolean;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  setLoadClientTokenObj(loadClientToken: LoadClientToken) {
    this.loadClientToken = loadClientToken;
  }

  getLoadClientTokenObj(): LoadClientToken {
    return this.loadClientToken;
  }

  throwError(errorResp: HttpErrorResponse) {
    if (errorResp.error.ExceptionMessage == 'Access denied, please log on first.') {
      this.redirectToLanding();
    } else {
      console.log('Get Response Error : ', errorResp);
    }
  }

  async getLoadClientToken(): Promise<LoadClientToken> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }), 'withCredentials': true
    };
    return await this.http.get<LoadClientToken>(this.apiPath + 'loadClientToken', httpOptions).toPromise().then((response) => {
      this.loadClientToken = new LoadClientToken();
      this.loadClientToken.setData(response);
      if (this.loadClientToken.UserName.length == 0) {
        this.redirectToLanding();
        return;
      }

      if (response.OMWFeature.IsValid || response.OMWLiteFeature.IsValid || response.PFFeature.IsValid || response.PFLiteFeature.IsValid) {
        this.menuItems[0]["hasAccess"] = true;
      }
      else {
        this.menuItems[0]["hasAccess"] = false;
      }
      this.menuItems[1]["hasAccess"] = response.OMWFeature.IsValid;
      this.menuItems[2]["hasAccess"] = response.OMWLiteFeature.IsValid;
      this.menuItems[3]["hasAccess"] = response.PFFeature.IsValid;
      this.menuItems[4]["hasAccess"] = response.PFLiteFeature.IsValid;
      return this.loadClientToken;
    });
  }

  compressImage(src, newX, newY) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }


  getBackColor(startValue, endValue, value, middleRate, isMarketed) {

    value = Number(value);
    startValue = Number(startValue);
    endValue = Number(endValue);
    if ((value == 0) && isMarketed) {
      return "#e7e7e7";
    }

    var startColor = {
      R: 255,
      G: 50,
      B: 50
    };
    var endColor = {
      R: 99,
      G: 239,
      B: 156
    };
    var middleColor = {
      R: 248,
      G: 230,
      B: 47
    };

    if (value == endValue)
      return Formular.toHexColor(endColor.R, endColor.G, endColor.B);

    if (value == startValue)
      return Formular.toHexColor(startColor.R, startColor.G, startColor.B);

    var middleValue = ((endValue - startValue) * middleRate) + startValue;
    var topRate = (value - middleValue) / (endValue - middleValue);
    var bottomRate = (value - startValue) / (middleValue - startValue);

    if (value == middleValue)
      return Formular.toHexColor(middleColor.R, middleColor.G, middleColor.B);

    var startRed = startColor.R;
    var startGreen = startColor.G;
    var startBlue = startColor.B;
    var endRed = endColor.R;
    var endGreen = endColor.G;
    var endBlue = endColor.B;
    var middleRed = middleColor.R;
    var middleGreen = middleColor.G;
    var middleBlue = middleColor.B;
    var newRed = 1;
    var newGreen = 1;
    var newBlue = 1;

    if (value < middleValue) {
      newRed = Math.floor(startRed + ((middleRed - startRed) * bottomRate));
      newGreen = Math.floor(startGreen + ((middleGreen - startGreen) * bottomRate));
      newBlue = Math.floor(startBlue + ((middleBlue - startBlue) * bottomRate));
    }
    else {
      newRed = Math.floor(middleRed + ((endRed - middleRed) * topRate));
      newGreen = Math.floor(middleGreen + ((endGreen - middleGreen) * topRate));
      newBlue = Math.floor(middleBlue + ((endBlue - middleBlue) * topRate));
    }
    return Formular.toHexColor(newRed, newGreen, newBlue);
  }

  getMatrixBrush(value, maxValue, maxValueLess, baseValue) {
    if (value == maxValue)
      return '#00B050'; //Green
    if (baseValue != null && value == baseValue)
      return '#ECECEC'; // Grey
    if (value > maxValueLess)
      return '#C6EFCE'; // light green
    return '#FFC4CE'; // red
  };

  getCriteriaValue(criteriaKey: FVPCriteriaKey): Observable<Array<ItemCode>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<Array<ItemCode>>(this.apiPath + 'getcriteriavalue', criteriaKey, httpOptions);
  }

  getOMWPacker(criteriaKey: FVPCriteriaKey, criteriaValues: Array<string>): Observable<Array<FVPPacker>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    var payload = { criteriaKey: criteriaKey, criteriaValues: criteriaValues };
    return this.http.post<Array<FVPPacker>>(this.apiPath + 'getomwpacker', payload, httpOptions);
  }


  setTSwineCarcassDetailObj(tSwineCarcassDetail: TSwineCarcassDetail) {
    this.tSwineCarcassDetail = tSwineCarcassDetail;
  }

  getTSwineCarcassDetailObj(): TSwineCarcassDetail {
    return this.tSwineCarcassDetail;
  }

  getOMWData(criteriaKey: FVPCriteriaKey, criteriaValue: FVPCriteriaValue): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    var payload = { criteriaKey: criteriaKey, criteriaValue: criteriaValue };
    return this.http.post<any>(this.apiPath + 'getomwdata', payload, httpOptions);
  }

  saveOMWOptimalCase(optimalcase: OptimalCase, isCover: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };

    var payload = { optimalCase: optimalcase, iscover: isCover };
    return this.http.post<any>(this.apiPath + 'saveOMWOptimalCase', payload, httpOptions);
  }

  saveOMWLiteOptimalCase(optimalcase: OptimalCaseY, isCover: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };

    var payload = { optimalCase: optimalcase, iscover: isCover };
    return this.http.post<any>(this.apiPath + 'saveOMWLiteOptimalCase', payload, httpOptions);
  }

  setPaymentGridObj(paymentGrid: PaymentGrid) {
    this.paymentGrid = paymentGrid;
  }

  getPaymentGridObj(): PaymentGrid {
    return this.paymentGrid;
  }

  setPFPaymentGridObj(paymentGrid: PFPaymentGrid) {
    this.pfPaymentGrid = paymentGrid;
  }

  getPFPaymentGridObj(): PFPaymentGrid {
    return this.pfPaymentGrid;
  }


  getPaymentGrid(paymentGridID: number): Observable<PaymentGrid> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<PaymentGrid>(this.apiPath + 'getpaymentgrid', paymentGridID, httpOptions);
  }


  setPerformanceObj(Performance: Performance) {
    this.performance = Performance;
  }


  getPerformanceObj(): Performance {
    return this.performance;
  }

  getPerformance(performanceID: number): Observable<Performance> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<any>(this.apiPath + 'getperformance', performanceID, httpOptions);
  }
  getFVPPerformance(performanceID: number): Observable<FVPPerformance> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<FVPPerformance>(this.apiPath + 'getperformance', performanceID, httpOptions);
  }

  setPFPerformanceObj(Performance: PFPerformance) {
    this.pfPerformance = Performance;
  }


  getPFPerformanceObj(): PFPerformance {
    return this.pfPerformance;
  }

  getPFPerformance(performanceID: number): Observable<PFPerformance> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<PFPerformance>(this.apiPath + 'getperformance', performanceID, httpOptions);
  }

  getOMWLiteBatch(criteriaKey: LiteCriteriaKey, performanceParaID: number, paymentGridID: number): Observable<OMWLiteBatchData> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    var payload = { criteriaKey: criteriaKey, performanceParaID: performanceParaID, paymentGridID: paymentGridID };
    return this.http.post<OMWLiteBatchData>(this.apiPath + 'getomwlitebatch', payload, httpOptions);
  }

  getFeedProgram(performanceID: number): Observable<Array<PerformanceFeedProgram>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.get<Array<PerformanceFeedProgram>>(this.apiPath + 'getfeedprogram?performanceID=' + performanceID, httpOptions);
  }

  getGrowthADFI(performanceID: number): Observable<PerformanceGrowthADFI> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.get<PerformanceGrowthADFI>(this.apiPath + 'getgrowthadfi?performanceID=' + performanceID, httpOptions);
  }

  getAdjustment(performanceID: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.get<any>(this.apiPath + 'getadjustment?performanceID=' + performanceID, httpOptions);
  }

  getOMWLitePaymentGridDetail(paymentGridID: number): Observable<OMWLitePaymentGridDetail> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    OMWLitePaymentGridDetail
    return this.http.get<OMWLitePaymentGridDetail>(this.apiPath + 'getomwlitepaymentgriddetail?paymentGridID=' + paymentGridID, httpOptions);
  }

  getOMWLiteData(criteriaKey: LiteCriteriaKey, criteriaValue: OMWLiteCriteriaValue): Observable<OMWLiteData> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    var payload = { criteriaKey: criteriaKey, criteriaValue: criteriaValue };
    return this.http.post<OMWLiteData>(this.apiPath + 'getomwlitedata', payload, httpOptions);
  }

  getCriteriaValueByGroupDate(criteriaKey: FVPCriteriaKey): Observable<Array<ItemCode>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<Array<ItemCode>>(this.apiPath + 'getcriteriavaluebygroupdate', criteriaKey, httpOptions);
  }

  getPFPacker(criteriaKey: FVPCriteriaKey, criteriaValues: Array<string>): Observable<Array<FVPPacker>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    var payload = { criteriaKey: criteriaKey, criteriaValues: criteriaValues };
    return this.http.post<Array<FVPPacker>>(this.apiPath + 'getpfpacker', payload, httpOptions);
  }

  setPFDataObj(PFData: PFData) {
    this.pfData = PFData;
  }

  getPFDataObj(): PFData {
    return this.pfData;
  }

  getPFData(criteriaKey: FVPCriteriaKey, criteriaValue: FVPCriteriaValue, isAttrition: boolean): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    let payload = { criteriaKey: criteriaKey, criteriaValue: criteriaValue, isAttrition: isAttrition };
    return this.http.post<any>(this.apiPath + 'getpfdata', payload, httpOptions);
  }

  getAttritionData(criteriaKey: FVPCriteriaKey, criteriaValue: FVPCriteriaValue, isAttrition: boolean): Observable<IPFData> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    let payload = { criteriaKey: criteriaKey, criteriaValue: criteriaValue, isAttrition: isAttrition };
    return this.http.post<IPFData>(this.apiPath + 'getpfdata', payload, httpOptions);
  }

  setPFBlockedDataObj(pfBlockedData: PFBlockedData) {
    this.pfBlockedData = pfBlockedData;
  }

  getPFBlockedDataObj(): PFBlockedData {
    return this.pfBlockedData;
  }

  getBlockedData(dataID: string, blockedIndex: number): Observable<Array<BlockData>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    let payload = { dataID: dataID, blockedIndex: blockedIndex };
    return this.http.post<Array<BlockData>>(this.apiPath + 'getblockeddata', payload, httpOptions);
  }

  getAttritionBlockedData(dataID: string, blockedIndex: number): Observable<Array<ISwineCarcass>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    let payload = { dataID: dataID, blockedIndex: blockedIndex };

    return this.http.post<Array<ISwineCarcass>>(this.apiPath + 'getblockeddata', payload, httpOptions);
  }

  clearStaticData(dataID: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.get<any>(this.apiPath + 'clearstaticdata?dataID=' + dataID, httpOptions);
  }

  getMasterMetadataUnitByAccount(accountID: number): Observable<Array<AccountMetadataUnit>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<Array<AccountMetadataUnit>>(this.apiPath + 'getmastermetadataunitbyaccount', accountID, httpOptions);
  }

  getPFLitePaymentGrid(accountID: number, weightUnitID: number): Observable<Array<PFLitePaymentGrid>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    var payload = { accountID: accountID, weightUnitID: weightUnitID };
    return this.http.post<Array<PFLitePaymentGrid>>(this.apiPath + 'getpflitepaymentgrid', payload, httpOptions);
  }

  getPFLiteBatch(criteriaKey: LiteCriteriaKey, paymentGridID: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    let payload = { criteriaKey: criteriaKey, paymentGridID: paymentGridID };
    return this.http.post<any>(this.apiPath + 'getpflitebatch', payload, httpOptions);
  }

  getPFLitePaymentGridDetail(paymentGridID: number): Observable<Array<PFLitePaymentGridDetail>> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<Array<PFLitePaymentGridDetail>>(this.apiPath + 'getpflitepaymentgriddetail', paymentGridID, httpOptions);
  }

  getPFLiteData(criteriaKey: LiteCriteriaKey, paymentGridID: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    let payload = { criteriaKey: criteriaKey, paymentGridID: paymentGridID };
    return this.http.post<any>(this.apiPath + 'getpflitedata', payload, httpOptions);
  }

  getMorePaymentGrid(paymentGridIDs: Array<number>): Observable<Array<IFVPPaymentGrid>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<Array<IFVPPaymentGrid>>(this.apiPath + 'getmorepaymentgrid', paymentGridIDs, httpOptions);
  }


  setFeedProgramObj(FeedProgram: FeedProgram) {
    this.feedProgram = FeedProgram;
  }

  getFeedProgramObj(): FeedProgram {
    return this.feedProgram;
  }

  setGrowthADFIObj(growthADFI: PerformanceGrowthADFI) {
    this.growthADFI = growthADFI;
  }

  getGrowthADFIObj(): PerformanceGrowthADFI {
    return this.growthADFI;
  }

  setOMWLiteBatchObj(omwLiteBatchData: OMWLiteBatchData) {
    this.omwLiteBatchData = omwLiteBatchData;
  }

  setOMWLitePaymentGridDetailObj(omwLitePaymentGridDetail: OMWLitePaymentGridDetail) {
    this.omwLitePaymentGridDetail = omwLitePaymentGridDetail;
  }

  setOMWLiteDataObj(omwLiteData: OMWLiteData) {
    this.omwLiteData = omwLiteData;
  }

  setOMWLiteFeedProgramObj(feedProgram: FeedProgram) {
    this.omwLiteFeedProgram = feedProgram;
  }

  getOMWLiteGrowthADFIObj() {
    return this.omwLiteGrowthADFI;
  }

  getOMWLiteBatchObj() {
    return this.omwLiteBatchData;
  }

  getOMWLitePaymentGridDetailObj() {
    return this.omwLitePaymentGridDetail;
  }

  getOMWLiteDataObj() {
    return this.omwLiteData;
  }

  getOMWLiteFeedProgramObj() {
    return this.omwLiteFeedProgram;
  }

  setOMWLiteGrowthADFIObj(growthADFI: PerformanceGrowthADFI) {
    this.omwLiteGrowthADFI = growthADFI;
  }

  getAdjustmentADFIObj() {
    return this.omwLiteAdjustADFI;
  }

  setAdjustmentObj(adjustADFI: AdjustList) {
    this.omwLiteAdjustADFI = adjustADFI;
  }

  exportPPT(payload): Observable<ExportPPT> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<any>(this.apiPath + 'generatepowerpointasbyte', payload, httpOptions);
  }

  getUserName(): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      'withCredentials': true
    };
    return this.http.get<string>(this.apiPath + 'getusername', httpOptions);
  }

  async getUserNameAsync(): Promise<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      'withCredentials': true
    };
    return await this.http.get<string>(this.apiPath + 'getusername', httpOptions).toPromise().then((response) => {
      return response;
    });
  }

  bindMOFCXValues(ctx, para) {
    var calibrationXvalue = (para.Width - para.LeftWidth) / 10;
    ctx.beginPath();
    ctx.strokeStyle = this.linColor;
    for (var i = 0; i < 11; i++) {
      var xSize = calibrationXvalue * i;
      ctx.moveTo(para.LeftWidth, para.Height);
      ctx.lineTo(para.LeftWidth + xSize, para.Height);
      ctx.lineTo(para.LeftWidth + xSize, para.Height - 3);
      ctx.stroke();
      var value = para.XMinValue + (para.XMaxValue - para.XMinValue) * i / 10;
      ctx.fillStyle = this.strColor;
      ctx.font = '10px sans-serif';
      ctx.textBaseline = 'middle';
      ctx.fillText(Math.round(value) + '', para.LeftWidth + xSize - 20, para.Height + 10);
      ctx.stroke();
    }
  }

  bindMOFCYValues(ctx, para) {

    var calibrationYvalue = (para.Height - para.TopValue) / 10;
    ctx.beginPath();
    ctx.strokeStyle = this.linColor;
    for (var i = 0; i < 11; i++) {
      var yVigor = calibrationYvalue * i;
      ctx.moveTo(para.LeftWidth, para.TopValue);
      ctx.lineTo(para.LeftWidth, para.TopValue + yVigor);
      ctx.lineTo(para.LeftWidth - 3, para.TopValue + yVigor);
      ctx.stroke();
      var value = para.YMinValue + (para.YMaxValue - para.YMinValue) * i / 10;
      ctx.fillStyle = this.strColor;
      ctx.font = '10px sans-serif';
      ctx.textBaseline = 'middle';
      ctx.fillText(Formular.Round(value, 1), para.LeftWidth - 40, para.Height - yVigor - 5);
      ctx.stroke();
    }
  }

  bindMOFCLine(ctx, mofcCollection, para) {
    var count = 0;
    var pointX = 0;
    var pointY = 0;
    ctx.beginPath();
    ctx.strokeStyle = this.linColor;

    var mofc = null;
    var mofcCount = mofcCollection.length;

    for (var i = 0; i < mofcCount; i++) {

      mofc = mofcCollection[i];
      if (mofc.CarcassWeight < para.XMinValue) {
        count++;
        continue;
      }

      pointX = (mofc.CarcassWeight - para.XMinValue) * para.XWidthPerValue + para.LeftWidth;
      pointY = para.Height - (mofc.ReturnOverFeedCostPlus - para.YMinValue) * para.YHeightPerValue;

      if (i == 0) {
        ctx.moveTo(pointX, pointY);
      }
      else {
        ctx.lineTo(pointX, pointY);
      }
    }
    ctx.stroke();
  }

  bindMOFCPoint(ctx, mofcCollection, para) {

    var mofc = null;
    var mofcCount = mofcCollection.length;
    var left = 0;
    var top = 0;
    for (var i = 0; i < mofcCount; i++) {
      mofc = mofcCollection[i];
      if (mofc.CarcassWeight < para.XMinValue)
        continue;
      left = (mofc.CarcassWeight - para.XMinValue) * para.XWidthPerValue + para.LeftWidth;
      top = para.Height - (mofc.ReturnOverFeedCost - para.YMinValue) * para.YHeightPerValue;
      ctx.beginPath();
      ctx.fillStyle = this.signColor;
      ctx.arc(left, top, 2, 0, Math.PI * 2, true);
      ctx.closePath();
      ctx.fill();
    }
  }

  getPointChatParameter(mofcCollection, top, left, width, height, WeightUnitFactor) {
    var yMinValue = Number.MAX_VALUE;
    var yMaxValue = Number.MAX_VALUE * -1;
    var xMaxValue = Number.MAX_VALUE * -1;
    var xMinValue = Math.floor(100 * WeightUnitFactor);

    var mofcCount = mofcCollection.length;
    var mofc = null;
    for (var i = 0; i < mofcCount; i++) {
      mofc = mofcCollection[i];
      if (yMinValue > mofc.ReturnOverFeedCost)
        yMinValue = mofc.ReturnOverFeedCost;
      if (yMinValue > mofc.ReturnOverFeedCostPlus)
        yMinValue = mofc.ReturnOverFeedCostPlus;
      if (yMaxValue < mofc.ReturnOverFeedCost)
        yMaxValue = mofc.ReturnOverFeedCost;
      if (yMaxValue < mofc.ReturnOverFeedCostPlus)
        yMaxValue = mofc.ReturnOverFeedCostPlus;
      if (xMaxValue < mofc.CarcassWeight)
        xMaxValue = mofc.CarcassWeight;
    }
    if (mofcCount == 0) {
      yMinValue = 0;
      yMaxValue = 0;
      xMaxValue = xMinValue;
    }

    if (Math.floor(yMinValue) == Math.floor(yMaxValue)) {
      yMaxValue = yMinValue + 11;
    }
    if (Math.floor(xMinValue) == Math.floor(xMaxValue)) {
      xMaxValue = xMinValue + 10;
    }

    var pointParameter = new SignPointParameter();
    pointParameter.Height = height;
    pointParameter.LeftWidth = left;
    pointParameter.TopValue = top;
    pointParameter.XMinValue = xMinValue;
    pointParameter.XMaxValue = xMaxValue;
    pointParameter.Width = width;
    pointParameter.XWidthPerValue = (width - left) / (xMaxValue - xMinValue);
    pointParameter.YHeightPerValue = (height - top) / (yMaxValue - yMinValue);
    pointParameter.YMinValue = yMinValue;
    pointParameter.YMaxValue = yMaxValue;
    return pointParameter;
  }

  redirectToSigout() {
    window.location.href = this.loadClientToken.SignOutURL;
  }

  redirectToLanding() {
    window.location.href = this.loadClientToken.SpeciesURL;
  }

}