import { DietCost } from "./DietCost";

export class Formular {

    static RemoveEndZero(value: number): string {
        let txt: string = value.toString().trim();
        if (txt.indexOf(".") < 0)
            return txt;

        let lastStr: string = txt.substring(txt.length - 1, 1);
        while (lastStr == "0") {
            txt = txt.substring(0, txt.length - 1);
        }
        return txt;
    }

    static GetValueDecimalsByStr(txt: string): number {
        if (txt.indexOf(".") < 0)
            return 0;

        return txt.length - txt.lastIndexOf(".") - 1;
    }

    static GetValueDecimalsByNum(value: number): number {
        let txt: string = Formular.RemoveEndZero(value);
        return Formular.GetValueDecimalsByStr(txt);
    }

    static Round(value: number, decimals: number): number {
        return parseFloat(value.toFixed(decimals));
    }

    static Divide(dividend: number, divisor: number): number {
        return divisor == 0 ? 0 : dividend / divisor;
    }

    static GetDietFeedCost(dietCostCollection: DietCost[], adfi: number, preAdfi: number): number {
        let cumulativeAdfi: number = preAdfi + adfi * 7;
        let dietCostCount: number = dietCostCollection.length;
        let diet: DietCost;
        let preDiet: DietCost;
        let daysOfP1: number = 0;
        if (dietCostCount == 0)
            return 0;

        if (cumulativeAdfi > dietCostCollection[dietCostCount - 1].SumFeedBudget) {
            return dietCostCollection[dietCostCount - 1].CostTon;
        }

        for (let i = dietCostCount - 1; i > 0; i--) {
            diet = dietCostCollection[i];
            preDiet = dietCostCollection[i - 1];
            if (cumulativeAdfi <= diet.SumFeedBudget && cumulativeAdfi > preDiet.SumFeedBudget) {
                if (preAdfi <= preDiet.SumFeedBudget) {
                    daysOfP1 = (preDiet.SumFeedBudget - preAdfi) / adfi;
                    return (daysOfP1 * preDiet.CostTon + (7 - daysOfP1) * diet.CostTon) / 7;
                }

                return diet.CostTon;
            }
        }

        return dietCostCollection[0].CostTon;
    }

   static toHexColor(r:number, g:number, b:number):string {
        var hex = '#';
        var hexStr = '0123456789ABCDEF';
        var low = r % 16;
        var high = (r - low) / 16;
        hex += hexStr.charAt(high) + hexStr.charAt(low);
        low = g % 16;
        high = (g - low) / 16;
        hex += hexStr.charAt(high) + hexStr.charAt(low);
        low = b % 16;
        high = (b - low) / 16;
        hex += hexStr.charAt(high) + hexStr.charAt(low);
        return hex;
    }
}