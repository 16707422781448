import { AccountMetadataUnit } from "./classes/AccountMetadataUnit";
import { AccountName } from "./classes/AccountName";
import { AttritionMatrixExtend } from "./classes/AttritionMatrixExtend";
import { MatrixGrid } from "./classes/MatrixGrid";
import { MatrixUnitValue } from "./classes/MatrixUnitValue";
import { PerformanceFeedProgram } from "./classes/PerformanceFeedProgram";
import { PerformanceGrowthADFI } from "./classes/PerformanceGrowthADFI";
import { PerformanceParameter } from "./classes/PerformanceParameter";
import { PFMatrixExtend } from "./classes/PFMatrixExtend";
import { PFLitePaymentGrid } from "./mockDataClass/PFLitePaymentGird";
import { PFLitePaymentGridDetail } from "./mockDataClass/PFLItePaymentGridDetails";

export class PFLiteSession {

    private static instance: PFLiteSession;

    IsValid!: boolean;
    FeatureID!: number;
    AccountCollection!: AccountName[];
    WeightUnit!: AccountMetadataUnit;
    CurrencyUnit!: AccountMetadataUnit;
    MatrixExtend!: PFMatrixExtend;
    MatrixExtendAttrition!: AttritionMatrixExtend;
    DateRangeMax!: Date;
    DateRangeMin!: Date;
    SelectCompany!: AccountName;
    SelectCriteria!: string;
    SelectRecord!: number;
    GetDataDate!: Date;
    SelectPerformancePara!: PerformanceParameter;
    FeedProgramCollection!: PerformanceFeedProgram[];
    GrowthADFICollection!: PerformanceGrowthADFI[];
    SelectedPaymentGrid!: PFLitePaymentGrid;
    PackerName!: string;
    //GridStyle!: PFLiteGridStyle;
    UnitValue!: MatrixUnitValue;
    PaymentGridDetail!: PFLitePaymentGridDetail[];
    MatrixGridCollection!: MatrixGrid[];

    constructor() {  }

    public static getInstance(): PFLiteSession {
        if(!PFLiteSession.instance) {
            PFLiteSession.instance = new  PFLiteSession();
        }

        return PFLiteSession.instance;
    }

}