import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'app-data-summary',
  templateUrl: './data-summary.component.html',
  styleUrls: ['./data-summary.component.scss']
})
export class DataSummaryComponent implements OnInit {

  @Input() dataSummary: any;
  selectorName: String;

  constructor() { }

  ngOnInit() {
    this.selectorName = this.dataSummary.name;
  }

  closeOutSummaryVisibility() {
    return (this.selectorName == 'Profit Finder') || 
           (this.selectorName == 'Profit Finder Y') || 
           (this.selectorName == 'Attrition') || 
           (this.selectorName == 'Attrition Y');
  }

  attritionEconomicsVisibility() {
    return (this.selectorName == 'Attrition') || 
           (this.selectorName == 'Attrition Y');
  }

  overAllVisibility() {
    return (this.selectorName == 'Profit Finder') || 
           (this.selectorName == 'Profit Finder Y') || 
           (this.selectorName == 'Attrition') || 
           (this.selectorName == 'Attrition Y');
  }

  attritionMetricsVisibility() {
    return (this.selectorName == 'Attrition') || 
           (this.selectorName == 'Attrition Y');
  }

  fvpMaxMofcAtPrimaryPackerVisibility() {
    return (this.selectorName == 'Attrition') || 
           (this.selectorName == 'Attrition Y');
  }

  cullsSecondaryMarketVisibility() {
    return (this.selectorName == 'Attrition') || 
           (this.selectorName == 'Attrition Y');
  }

  transportLossesVisibility() {
    return (this.selectorName == 'Attrition');
  }

}
