export class SwineMortality {

    MortalityWeek: number;
    HeadCount: number;
    Cause: string;
    MortalityType: string;
    MortalityComment: string;
    MortalityDate: string;

    constructor() { }

}

export interface ISwineMortality {
    A: number;
    B: number;
    C: string;
    D: string;
    E: string;
    F: string;
}