import { AccountName } from "./AccountName";
import { ColumnChatInstance } from "./ColumnChatInstance";
import { ItemCode } from "./ItemCode";
import { ItemName } from "./ItemName";
import { MatrixOptimizedValue } from "./MatrixOptimizedValue";
import { PerformanceAdjustment } from "./PerformanceAdjustment";
import { PerformanceFeedProgram } from "./PerformanceFeedProgram";
import { PerformanceGrowthADFI } from "./PerformanceGrowthADFI";
import { PerformanceParameter } from "./PerformanceParameter";
import { ProfitOptimizerCalculate } from "./ProfitOptimizerCalculate";

export class OMWSession {

    private static instance: OMWSession;

    public static REDUCE_STDEV_OF_MARKETING_BY: number = 0;
    
    IsValid?: boolean;
    AccountCollection?: AccountName[];
    DateRangeMin?: string;
    DateRangeMax?: string;
    SelectCompany?: AccountName;
    SelectCriteria?: string;
    SelectValues?: ItemCode[];
    AllValues?: ItemCode[];
    AllPacker?: ItemName[];
    SelectPacker?: ItemName;
    SelectRecord?: number;
    GetDataDate?: any;
    SelectPerformance!: PerformanceParameter;
    FeedProgramList!: PerformanceFeedProgram[];
    GrowthADFIList!: PerformanceGrowthADFI[];
    AdjustmentList!: PerformanceAdjustment[];
    Calculate!: ProfitOptimizerCalculate;
    OptimizedValue?: MatrixOptimizedValue;
    ResetOptimizedValue?: MatrixOptimizedValue;
    MOFCPopulationOptimal?: ColumnChatInstance[];
    MOFCPopulation?: ColumnChatInstance[];
    

    public static getInstance(): OMWSession {
        if(!OMWSession.instance) {
            OMWSession.instance = new OMWSession();
        }

        return OMWSession.instance;
    }

}