import { NgModule } from '@angular/core';
import { UserInputService } from '../portal/user-inputs/user-inputs-service';
import { LoaderComponent } from '../shared/loader/loader.component';
import { NgxSpinnerService } from "ngx-spinner";

// -- Services --
import { GridActivityService, AuthGuardService, FVPDataMappingService, DataFilterService } from './index';


@NgModule({
    providers: [
        AuthGuardService,
        GridActivityService,
        FVPDataMappingService,
        DataFilterService,
        LoaderComponent,
        UserInputService,
        NgxSpinnerService
    ]
})

export class ServiceModule { }
