import { SwineCarcassCore } from "./swine-carcass-core";

export class SwineCarcassDetail {

    OBS: number;
    CarcassDetailID: number;
    CarcassCore: SwineCarcassCore;
    CarcassYield: number;
    LiveWeight: number;

    constructor() { }

}

export interface ISwineCarcassDetail {
    A: number;
    B: number;
    C: number;
    D: string;
    E: number;
    F: number;
    G: string;
    H: number;
    I: number;
    J: number;
    K: number;
    L: number;
    M: boolean;
    N: boolean;
    O: boolean;
}