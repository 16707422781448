export interface IOMWLiteBatchData {
    BatchID: number;
    AccountID: number;
    PerformanceParameterID: number;
    PaymentGridID: number;
    BatchDate: any;
    WeightUnit: number;
    CurrencyUnit: number;
    CreateUser?: any;
    Yield: number;
}

export class OMWLiteBatchData {
    OMWLiteBatchData: IOMWLiteBatchData[];

    constructor() { }

    setData(omwLiteBatchData: OMWLiteBatchData) {
        this.OMWLiteBatchData = omwLiteBatchData.OMWLiteBatchData;
    }
}
