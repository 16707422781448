import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';

declare var $:any;

@Component({
    selector: 'app-fvp-modal',
    templateUrl: './fvp-modal.component.html',
    styleUrls: ['./fvp-modal.component.scss']
})
export class FvpModalComponent implements OnInit {

    @Input() modalContent:string;
    @Input() contentLabel:string;
    @Input() showCancel:boolean;

    @Output() inputModalEvent = new EventEmitter();
    @Output() msgModalEvent = new EventEmitter();
    inputValue:string="";
    showError:boolean = false;
    
    constructor() { }

    ngOnInit() { }

    emitInputData(){
        if(this.inputValue){
            this.showError = false;
            this.inputModalEvent.emit(this.inputValue);
            this.inputValue="";
            $('#fvp-input-modal').modal('hide');
        }else{
            this.showError = true;
        }
        
    }

    confirm(msg){
        this.msgModalEvent.emit(msg);
    }
   
}
