import { ItemCode } from "./base";
export interface WeightUnit {
  UnitID: number;
  DataObject: string;
  UnitType: number;
  AliasName: string;
  FieldUnit: string;
}

export interface CurrencyUnit {
  UnitID: number;
  DataObject: string;
  UnitType: number;
  AliasName: string;
  FieldUnit: string;
}

export interface LiteMetadataUnit {
  AccountID: number;
  WeightUnit: WeightUnit;
  CurrencyUnit: CurrencyUnit;
}

export interface LitePaymentGrid {
  ID: number;
  Name: string;
  AccountID: number;
  WeightType: number;
  PriceType: number;
}

export interface AccountList {
  id: number;
  name: string;
  defaultCOAName: string;
}

export interface Feature {
  IsValid: boolean;
  AccountList: AccountList[];
}

export interface GridMappingList {
  companyID: number;
  packerCode: string;
  paymentGridID: number;
  marketContract: string;
  createdBy: number;
  createdDt: string;
  packerName?: any;
  paymentGridName: string;
  accountName?: any;
  createdContactName?: any;
  mappedContactName?: any;
  mappedDt: string;
}

export interface AccountUnitList {
  CompanyID: number;
  FieldName: string;
  UnitName: string;
  UnitSymbol: string;
}

export interface PerformanceList {
  performanceParameterID: number;
  performanceParameterName: string;
  accountId: number;
  periodMarketing: number;
  carcassBasePrice: number;
  cullPercentofBaseADG: number;
  mortalityofBaseADG: number;
  cullPercentofBaseADFI: number;
  mortalityofBaseADFI: number;
  pigCost: number;
  deadLivePrice: number;
  nonAmbulatoryLivePrice: number;
  cullsLivePrice: number;
  overWeekPostWeanADG: number;
  overWeekPostWeanADFI: number;
  isDefault: boolean;
  isDeleted: boolean;
  createID: number;
  changedID: number;
}

export interface FVPCriteriaValue {
  PaymentGridIDs: Array<number>,
  Values: Array<string>,
  MaxRecord: number
}

export interface LiteCriteriaKey {
  CompanyID: number,
  DateStart: string,
  DateEnd: string
}

export interface AccountMetadataUnits {
  AccountMetadataUnit: Array<AccountMetadataUnit>
}

export interface AccountMetadataUnit {
  FieldUnit: string,
  DataObject: string,
  UnitID: number,
  UnitType: string,
  AliasName: string
}

export interface FVPCriteriaKey {
  CompanyID: number,
  CriteriaName: string,
  DateStart: string,
  DateEnd: string
}

export interface FVPPackers {
  FVPPacker: Array<FVPPacker>
}

export interface Packer {
  Code: string,
  Name: string
}

export interface FVPPacker {
  MarketContract: string,
  Packer: Packer
}

export interface criteriaData {
  ItemCode: Array<ItemCode>
}

export interface OMWLiteCriteriaValue {
  PaymentGridID: number,
  MaxRecord: number
}

export interface PFLitePaymentGrids {
  PFLitePaymentGrid: Array<PFLitePaymentGrid>
}

export interface PFLitePaymentGrid {
  AccountID: number,
  LastModifyDate: LastModifyDate,
  WeightType: number,
  CreateUser: CreateUser,
  DisableFlag: DisableFlag,
  LastModifyUser: LastModifyUser,
  ID: number,
  WeightUnitID: WeightUnitID,
  PriceType: number,
  Name: string
}

export interface LastModifyDate {
  'xsi:nil': XsiNill
}

export interface CreateUser {
  'xsi:nil': XsiNill
}

export interface DisableFlag {
  'xsi:nil': XsiNill
}

export interface LastModifyUser {
  'xsi:nil': XsiNill
}

export interface WeightUnitID {
  'xsi:nil': XsiNill
}

export interface XsiNill {
  'xsi:nil': boolean
}

export interface IInt {
  int: Array<number>
}

export interface AccountIDs {
  int: Array<IInt>
}

export class LoadClientToken {
  ContactID!: number;
  UserName!: string;
  LiteMetadataUnits!: LiteMetadataUnit[];
  LitePaymentGrids!: LitePaymentGrid[];
  OMWFeature!: Feature;
  OMWLiteFeature!: Feature;
  PFFeature!: Feature;
  PFLiteFeature!: Feature;
  AttritionLiteFeature!: Feature;
  PHEFeature!: Feature;
  AttritionEntryFeature!: Feature;
  //LastActivitySubject!: string;
  OMWCriteria!: string[];
  PFCriteria!: string[];
  GridMappingList!: GridMappingList[];
  AccountUnitList!: AccountUnitList[];
  PerformanceList!: PerformanceList[];
  UserLocaleType!: string;
  SpeciesURL!: string;
  SignOutURL!: string;

  constructor() { }

  setData(loadClientToken: LoadClientToken) {
    this.UserName = loadClientToken.UserName;
    this.LitePaymentGrids = loadClientToken.LitePaymentGrids;
    this.GridMappingList = loadClientToken.GridMappingList;
    this.UserLocaleType = loadClientToken.UserLocaleType;
    this.AccountUnitList = loadClientToken.AccountUnitList;
    this.PFCriteria = loadClientToken.PFCriteria;
    this.ContactID = loadClientToken.ContactID;
    this.PFLiteFeature = loadClientToken.PFLiteFeature;
    this.PFFeature = loadClientToken.PFFeature;
    this.AttritionEntryFeature = loadClientToken.AttritionEntryFeature;
    //this.LastActivitySubject = loadClientToken.LastActivitySubject;
    this.PHEFeature = loadClientToken.PHEFeature;
    this.LiteMetadataUnits = loadClientToken.LiteMetadataUnits;
    this.OMWCriteria = loadClientToken.OMWCriteria;
    this.PerformanceList = loadClientToken.PerformanceList;
    this.AttritionLiteFeature = loadClientToken.AttritionLiteFeature;
    this.OMWFeature = loadClientToken.OMWFeature;
    this.OMWLiteFeature = loadClientToken.OMWLiteFeature;
    this.SpeciesURL = loadClientToken.SpeciesURL;
    this.SignOutURL = loadClientToken.SignOutURL;
  }
}
