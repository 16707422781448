import domtoimage from 'dom-to-image';
import * as XLSX from 'xlsx';
import panzoom from 'panzoom';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

export class HomeService {

    private static instance: HomeService;

    public static getInstance(): HomeService {
        if (!HomeService.instance) {
            HomeService.instance = new HomeService();
        }

        return HomeService.instance;
    }

    zoomLevels = [0.05, 0.1, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.5, 0.54, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1, 1.05, 1.10, 1.15, 1.20, 1.25, 1.30, 1.35, 1.40, 1.45, 1.5, 1.55, 1.60, 1.65, 1.70, 1.75, 1.80, 1.85, 1.90, 1.95, 2];
    panZoomController;
    dataZoom: boolean = false;
    dataFilterButton: boolean = false;
    elementId: string = '';
    exportImage: boolean = false;
    exportExcel: boolean = false;
    currentZoomLevel: number;
    zoomFactor: number;
    i: number;

    setAttributes(dataZoom: boolean, dataFilter: boolean, elementId: string, exportImage: boolean, exportExcel: boolean) {
        this.dataZoom = dataZoom;
        this.dataFilterButton = dataFilter;
        this.elementId = elementId;
        this.exportImage = exportImage;
        this.exportExcel = exportExcel;
        if (this.dataZoom) {
            this.currentZoomLevel = Number(100);
            this.zoomFactor = 1;
            this.i = 19;
            this.setZoomController();
        }
    }

    exportToExcel(fileName:string,html:string){
        while(true){
            let start =html.indexOf('<!--');
            let end =html.indexOf('-->');
            if(start<=0){
                break;
            }
            html= html.replace(html.slice(start,end+3),'');
        }
        let blob = new Blob([html], {
            type: "application/vnd.ms-excel"
        });
        saveAs(blob, fileName);
    }

    exportToImage(){
        let self = this;
        let arrIsHTMLConvasID =['pffvpsummary','pfyFVPSummary']
        if(arrIsHTMLConvasID.indexOf(self.elementId)>=0){
            html2canvas(document.getElementById(self.elementId)).then(function (canvas) {
                canvas.toBlob(function (blob) {
                    saveAs(blob, self.elementId + ".jpg");
                });
            });
        }else{
            domtoimage.toBlob(document.getElementById(self.elementId),{ bgcolor: "white" })
            .then(function (blob) {
                saveAs(blob, self.elementId + '.png');
            });
        }
    }

    setZoomController(): void {
        this.panZoomController = null;
        this.i = 19;
        this.zoomFactor = 1;
        var self = this;
        if (self.elementId != '' && self.elementId != null) {
            setTimeout(() => {
                var element = document.getElementById(self.elementId);
                if (element != null) {
                    self.panZoomController = panzoom(element, {
                        maxZoom: 2,
                        minZoom: 0.5,
                        initialX: 0,
                        initialY: 0,
                        initialZoom: 1,
                        zoomSpeed: 0.05,
                        zoomDoubleClickSpeed: 1,
                        beforeWheel: function (event) {
                            if (event.deltaY < 0) {
                                self.zoomIn();
                                return;
                            }
                            else if (event.deltaY > 0) {
                                self.zoomOut();
                                return;
                            }
                        }
                    });
                }
            }, 1000);
        }
    }

    zoomIn() {
        if (this.panZoomController != null || this.panZoomController != undefined) {
            const transform = this.panZoomController.getTransform();
            const deltaX = transform.x;
            const deltaY = transform.y;
            const offsetX = this.zoomLevels[this.i] + deltaX;
            const offsetY = this.zoomLevels[this.i] + deltaY;
            if (this.zoomLevels[this.i] < this.panZoomController.getMaxZoom() && this.zoomLevels[this.i] >= 0.05) {
                //this.zoomFactor = parseFloat((this.zoomFactor + 0.05).toFixed(2));
                this.i++;
                this.currentZoomLevel = parseInt((this.zoomLevels[this.i] * 100).toFixed());
                this.panZoomController.smoothZoomAbs(offsetX, offsetY, this.zoomLevels[this.i]);
            }
        }
    }

    zoomOut() {
        if (this.panZoomController != null || this.panZoomController != undefined) {
            const transform = this.panZoomController.getTransform();
            const deltaX = transform.x;
            const deltaY = transform.y;
            const offsetX = this.zoomLevels[this.i] + deltaX;
            const offsetY = this.zoomLevels[this.i] + deltaY;
            if (this.zoomLevels[this.i] <= this.panZoomController.getMaxZoom() && this.zoomLevels[this.i] > 0.09) {
                //this.zoomFactor = parseFloat((this.zoomFactor - 0.05).toFixed(2));
                this.i--;
                this.currentZoomLevel = parseInt((this.zoomLevels[this.i] * 100).toFixed());
                this.panZoomController.smoothZoomAbs(offsetX, offsetY, this.zoomLevels[this.i]);
            }
        }
    }

}


