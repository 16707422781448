export class SignPointParameter {

    XWidthPerValue: number;
    YHeightPerValue: number;
    LeftWidth: number;
    Height: number;
    Width: number;
    XMinValue: number;
    XMaxValue: number;
    YMinValue: number;
    YMaxValue: number;
    TopValue: number;

}