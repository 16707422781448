import { Component } from '@angular/core';
import { FullValuePigService } from './services/full-value-pig.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {

    constructor(private fullValuePigService: FullValuePigService) { }

    ngOnInit() {
        this.fullValuePigService.getUserName().subscribe(response => {
            if (response.length == 0) {
                this.fullValuePigService.redirectToLanding();
            }
        });
    }
}
