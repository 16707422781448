import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// -- Services --
import { AuthGuardService } from 'src/app/services/auth-guard.service';


const routes: Routes = [

    {
        path: '',
        loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule),
        canActivate: [AuthGuardService]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
