import { DatePipe } from "@angular/common";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { AccountList, Feature, GridMappingList, PerformanceList, FVPCriteriaValue, FVPCriteriaKey, FVPPacker, ItemCode } from 'src/app/classes/entity'
import { FullValuePigService } from "src/app/services/full-value-pig.service";
import _ from 'lodash';
declare var $: any;
export abstract class HomeViewModel {

  feature: Feature;

  companies: Array<AccountList> = [];
  onLoadPaymentGrids: Array<GridMappingList> = [];
  paymentGrids: Array<GridMappingList> = [];
  criteria: Array<string> = [];
  values: Array<ItemCode> = [];
  fvpPackerMatrix: Array<FVPPacker> = [];
  packer: any[] = [];
  onLoadPerformanceParameters: Array<PerformanceList> = [];
  performanceParameters: Array<PerformanceList> = [];

  groupedPaymentGrids: any;
  groupedPerformanceParameters: any;

  getDataDate: string;
  getDataDateVisibility: boolean = false;

  startDate: Date;
  endDate: Date;
  companySelected: number;
  paymentGridSelected: number;
  criteriaSelected: string;
  valueSelected: Array<string> = [];
  packerMatrixSelected: string;
  performanceParameterSelected: number;
  numberOfRecordSelected: number;

  paymentGridIDs: Array<number> = [];

  valueSelectedItems: Array<ItemCode> = [];

  dropdownSettings: IDropdownSettings = {};

  criteriaKey: FVPCriteriaKey;
  criteriaValue: FVPCriteriaValue;

  fullValuePigService: FullValuePigService;
  datePipe: DatePipe;
  errorMessage: string = ''

  setDropdownSettings() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Code',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false,
      closeDropDownOnSelection: false,
      noDataAvailablePlaceholderText: ''
    };
  }

  groupPaymentGrids() {
    this.groupedPaymentGrids = _.groupBy(this.onLoadPaymentGrids, 'companyID');
  }

  groupPerformanceParameter() {
    this.groupedPerformanceParameters = _.groupBy(this.onLoadPerformanceParameters, 'accountId');
  }

  companyChanged(companySelected: number) {
    this.companySelected = companySelected;
    this.performanceParameters = this.groupedPerformanceParameters[companySelected];
    this.paymentGrids = this.groupedPaymentGrids[companySelected];
    if (this.paymentGrids == undefined) {
      $("#po-account-units-modal").modal({ backdrop: 'static', keyboard: false, show: true });
    }
    this.resetDataAfterCompanyChanged();
  }

  packerMatrixChanged(packerMatrixSelected) {
    this.packerMatrixSelected = packerMatrixSelected;
    this.paymentGridIDs = [];
    var self = this;
    this.paymentGrids.forEach(function (value) {
      if (value.packerCode == packerMatrixSelected) {
        self.paymentGridIDs.push(value.paymentGridID);
        self.paymentGridSelected = value.paymentGridID;
      }
    });
  }

  performanceParameterChanged(performanceParameterSelected) {
    this.performanceParameterSelected = performanceParameterSelected;
  }

  getFVPCriteriaKey() {
    this.criteriaKey = {
      CompanyID: this.companySelected,
      CriteriaName: this.criteriaSelected,
      DateStart: this.datePipe.transform(this.startDate, 'MM/dd/yyyy'),
      DateEnd: this.datePipe.transform(this.endDate, 'MM/dd/yyyy')
    };
    return this.criteriaKey;
  }

  getValueSelected() {
    var self = this;
    this.valueSelected = [];
    this.valueSelectedItems.forEach(function (value) {
      self.valueSelected.push(value.Code);
    });
    return this.valueSelected;
  }

  onDeSelectAll() {
    this.fvpPackerMatrix = [];
    this.packer = [];
    this.valueSelectedItems = [];
  }

  resetDataAfterCompanyChanged() {
    this.paymentGridSelected = undefined;
    this.criteriaSelected = undefined;
    this.values = [];
    this.valueSelectedItems = [];
    this.valueSelected = [];
    this.fvpPackerMatrix = [];
    this.packer = [];
    this.packerMatrixSelected = undefined;
    this.performanceParameterSelected = undefined;
    this.numberOfRecordSelected = undefined;
  }

  validateStartDate() {
    if (new Date(this.startDate) > new Date()) {
      this.startDate = undefined;
      return { status: false, message: 'Start Date cannot be greater than today date' };
    }
    if ((this.endDate != undefined) && (new Date(this.startDate) >= new Date(this.endDate))) {
      this.startDate = undefined;
      return { status: false, message: 'Start Date cannot be equal or greater than end date' };
    }
    return { status: true, message: 'Valid start date' };
  }

  validateEndDate() {
    if (new Date(this.endDate) > new Date()) {
      this.endDate = undefined;
      return { status: false, message: 'End Date cannot be greater than today date' };
    }
    if ((this.startDate != undefined) && (new Date(this.startDate) > new Date(this.endDate))) {
      this.endDate = undefined;
      return { status: false, message: 'End Date cannot be greater than today date' };
    }
    return { status: true, message: 'Valid start date' };
  }

}
