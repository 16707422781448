import { Injectable } from '@angular/core';
import { Router, CanActivate, Route, CanLoad } from '@angular/router';
import { FullValuePigService } from './full-value-pig.service';

@Injectable()
export class AuthGuardService implements CanActivate, CanLoad {

    constructor(
        private router: Router,
        private fullValuePigService: FullValuePigService
    ) { }

    async canActivate() {
        let currentUser: String = await this.fullValuePigService.getUserNameAsync();
        if (currentUser.length != 0) {
            return true;
        }
        else {
            this.fullValuePigService.redirectToLanding();
            return false;
        }
        //return true;
    }

    canLoad(route: Route): boolean {
        var popUp: boolean = false;
        var module = this.fullValuePigService.menuItems.find(element => element["url"] == route.data.url);
        for (let i = 0; i < this.fullValuePigService.menuItems.length; i++) {
            if (this.fullValuePigService.menuItems[i]["hasAccess"] == true) {
                popUp = false;
                break;
            }
            else {
                popUp = true;
            }
        }
        if (popUp == true) {
            var ok = confirm("No feature found. Please contact the administrator");
            if (ok || !ok) {
                this.fullValuePigService.redirectToLanding();
            }
        }
        return module["hasAccess"];
    }
}
