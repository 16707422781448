import { FVPCriteriaValue, LoadClientToken } from 'src/app/classes/entity';
import { HomeViewModel } from './HomeViewModel';
import { FullValuePigService } from 'src/app/services/full-value-pig.service';
import { DatePipe } from '@angular/common';
declare var $: any;

export class ProfitOptimizerViewModel extends HomeViewModel {

  static profitOptimizerViewModel: ProfitOptimizerViewModel = null;
  private constructor() {
    super();
  }

  static getInstance(): ProfitOptimizerViewModel {
    if (this.profitOptimizerViewModel == null) {
      this.profitOptimizerViewModel = this.createInstance();
    }
    return this.profitOptimizerViewModel;
  }

  static createInstance(): ProfitOptimizerViewModel {
    this.profitOptimizerViewModel = new ProfitOptimizerViewModel();
    return this.profitOptimizerViewModel;
  }

  setData(loadClientToken: LoadClientToken, fullValuePigService: FullValuePigService, datePipe: DatePipe) {
    this.fullValuePigService = fullValuePigService;
    this.datePipe = datePipe;
    this.feature = loadClientToken.OMWFeature;
    this.onLoadPaymentGrids = loadClientToken.GridMappingList;
    this.criteria = loadClientToken.OMWCriteria;
    this.onLoadPerformanceParameters = loadClientToken.PerformanceList;
    this.companies = this.feature.AccountList;
    this.groupPaymentGrids();
    this.groupPerformanceParameter();
  }

  criteriaChanged(criteriaSelected: string) {
    this.valueSelectedItems = [];
    this.criteriaSelected = criteriaSelected;
    if (this.startDate != undefined && this.endDate != undefined) {
      this.getCriteriaValue();
      this.resetDataAfterCriteriaChanged();
    }
  }

  getCriteriaValue() {
    this.fullValuePigService.getCriteriaValue(this.getFVPCriteriaKey()).subscribe(
      response => {
        if (response.length > 0)
          this.values = response;
        else {
          $('#po-get-data-modal').modal({ backdrop: 'static', keyboard: false, show: true });
        }
      },
      errorResp => {
        this.fullValuePigService.throwError(errorResp);
      }
    );
  }

  onValueSelect(items: any) {
    this.fvpPackerMatrix = [];
    this.packer = [];
    this.getOMWPacker();
  }

  onValueDeSelect(items: any) {
    this.fvpPackerMatrix = [];
    this.packer = [];
    if (this.valueSelectedItems.length > 0) {
      this.getOMWPacker();
    }
  }

  onSelectAll(items: any) {
    this.fvpPackerMatrix = [];
    this.packer = [];
    this.valueSelectedItems = items;
    this.getOMWPacker();
  }

  getOMWPacker() {
    this.fullValuePigService.getOMWPacker(this.getFVPCriteriaKey(), this.getValueSelected()).subscribe(
      response => {
        this.packer = [];
        this.fvpPackerMatrix = response;
        this.packerFilter();
      },
      errorResp => {
        this.fullValuePigService.throwError(errorResp);
      }
    );
  }

  packerFilter() {
    // this.fvpPackerMatrix.forEach(original => {
    //   if(original.MarketContract=="")
    //   this.packer.push(original)
    // });   
    let self = this;
    self.packer = [];
    self.fvpPackerMatrix.forEach((packerMatrix) => {
      self.paymentGrids.forEach((paymentGrid) => {
        if ((packerMatrix.Packer.Code == paymentGrid.packerCode) && (packerMatrix.MarketContract == paymentGrid.marketContract)) {
          if ((self.packer.findIndex(i => i.paymentGridID === paymentGrid.paymentGridID)) == -1) {
            self.packer.push(paymentGrid);
          }
        }
      });
    });
  }

  numberOfRecordChanged(numberOfRecordSelected) {
    this.numberOfRecordSelected = numberOfRecordSelected;
  }

  validateProfitOptimizerData() {
    return (this.startDate != undefined) && (this.endDate != undefined) && (this.companySelected != undefined) && (this.criteriaSelected != undefined) && (this.valueSelectedItems.length > 0) && (this.packerMatrixSelected != undefined) && (this.performanceParameterSelected != undefined) && (this.numberOfRecordSelected != undefined);
  }

  getFVPCriteriaValue(): FVPCriteriaValue {
    this.criteriaValue = {
      PaymentGridIDs: this.paymentGridIDs,
      Values: this.getValueSelected(),
      MaxRecord: this.numberOfRecordSelected
    };
    return this.criteriaValue;
  }


  resetDataAfterCriteriaChanged() {
    this.fvpPackerMatrix = [];
    this.packer = [];
    this.packerMatrixSelected = undefined;
    this.performanceParameterSelected = undefined;
    this.numberOfRecordSelected = undefined;
  }

}
