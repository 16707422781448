export enum MatrixName {
    Null,
    Tyson,
    Swift,
    Cargill,
    Morrell,
    Triumph,
    FarmlandMorrell,
    Hormel,
    IPC,
    Swift2008,
    Mapleleaf,
    Smithfield,
    Seaboard,
    Hatfield,
    MapleLeafCustom,
    CargillPork,
    Tulip,
    HormelISM,
    HormelCustom,
    MapleLeafSMH,
    PATEL,
    PrimeFoods,
    MapleLeafSR,
    TulipSTO,
    OMWLite,
    PFLite,
    AttritionLite,
    FarmlandMorrellCustom,
    Cranswick,
    Martini
}

export enum WeightType {
    LiveWeight = 1,
    CarcassWeight = 2
}

export enum GridType {
    PercentOfBasePrice = 1,
    ValuePerWeight = 2
}

export enum QualityGradeType {
    FatMeasurement = 1,
    LeanPercent = 2,
    Grade = 3,
    None = 4,
    Quality = 5
}

export enum SectionType {
    First = 1,
    Middle = 2,
    End = 3
}