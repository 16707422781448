import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { PerformanceParameter } from 'src/app/classes/entity/performace';
import { Observable } from 'rxjs/Observable';
import { PerformanceFeedProgram, PerformanceAdjustment, PerformanceGrowthADFI } from 'src/app/classes/entity/performace';
import { AbstractService } from './abstract.service';
import { parseLazyRoute } from '@angular/compiler/src/aot/lazy_routes';

@Injectable()
export class InputService extends AbstractService {

	constructor(private http: HttpClient) {
		super();

	}

	getHttpOptions(): any {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			'withCredentials': true
		};
		return httpOptions;
	}

	LoadPerfParamDetail(company): Observable<PerformanceParameter> {

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			'withCredentials': true
		};
		return this.http.get<PerformanceParameter>(this.apiPath + "getPerformanceParameter?accountId=" + company, httpOptions);
	}

	LoadPerformanceGrowthADFI(perfParmId): Observable<PerformanceGrowthADFI[]> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			'withCredentials': true
		};
		return this.http.get<PerformanceGrowthADFI[]>(this.apiPath + "GetPerformanceGrowthADFI?performanceParameterId=" + perfParmId, httpOptions);
	}

	LoadPerformanceFeedProgram(perfParmId): Observable<PerformanceFeedProgram[]> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			'withCredentials': true
		};
		return this.http.get<PerformanceFeedProgram[]>(this.apiPath + "GetPerformanceFeedProgram?performanceParameterId=" + perfParmId, httpOptions);
	}

	LoadPerformanceAdjustment(companyID, performanceParameterID): Observable<PerformanceAdjustment> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			'withCredentials': true
		};
		return this.http.get<PerformanceAdjustment>(this.apiPath + "getPerformanceAdjustment?accountId=" + companyID + "&performanceID=" + performanceParameterID, httpOptions);
	}

	savePerformanceParameter(payload) {
		return this.http.post(this.apiPath + "savePerformanceParameter", payload, this.getHttpOptions());
	}
	savePerformanceAdjustment(payload) {
		return this.http.post(this.apiPath + "savePerformanceAdjustment", payload, this.getHttpOptions());
	}

	saveFeedProgram(payload) {
		return this.http.post(this.apiPath + "saveFeedProgram", payload, this.getHttpOptions());
	}

	deletePerformanceParameter(accountID, performanceParameterID) {
		return this.http.post(this.apiPath + "deletePerformanceParameter?performanceParameterID=" + performanceParameterID, null, this.getHttpOptions());
	}

	updatePerformanceParameterStatus(parameterID) {

		return this.http.post(this.apiPath + "updatePerformanceParameterStatus?performanceParameterId=" + parameterID, null, this.getHttpOptions());
	}
}