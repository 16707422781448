import { Component, HostListener, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { UserInputService } from '../../user-inputs/user-inputs-service';
import { FullValuePigService } from 'src/app/services/full-value-pig.service';
import { AttritionViewModel } from '../../view-model/AttritionViewModel';
import { AttritionLiteViewModel } from '../../view-model/AttritionLiteViewModel';
import { ProfitOptimizerViewModel } from '../../view-model/ProfitOptimizerViewModel';
import { ProfitOptimizerLiteViewModel } from '../../view-model/ProfitOptimizerLiteViewModel';
import { ProfitFinderViewModel } from '../../view-model/ProfitFinderViewModel';
import { ProfitFinderLiteViewModel } from '../../view-model/ProfitFinderLiteViewModel';
import { PFLiteSession } from '../../profit-finder-y/PFLiteCalculations/PFLiteSession';
import { OMWSession } from '../../profit-optimizer/Calculations/classes/OMWSession';
import { OMWLiteSession } from '../../profit-optimizer-y/po-y-calculations/classes/OMWLiteSession';
import { PFSession } from '../../profit-finder/calculations/classes/PFSession';
import * as moment from 'moment';
import { HomeService } from './abstract-class/home-service';
declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    homeService: HomeService = HomeService.getInstance();
    @Input() pageSummary: any;
    @Output() export_event_emitter = new EventEmitter<string>();

    // zoomLevels = [0.05, 0.1, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.5, 0.54, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1, 1.05, 1.10, 1.15, 1.20, 1.25, 1.30, 1.35, 1.40, 1.45, 1.5, 1.55, 1.60, 1.65, 1.70, 1.75, 1.80, 1.85, 1.90, 1.95, 2];
    // currentZoomLevel: number = this.zoomLevels[19];
    // panZoomController;
    pageTitle: string = '';
    dataZoom: boolean = false;
    languageSelectDropDown: boolean = false;
    isFullScreen: boolean = false;
    backNavigation: boolean = false;

    dataFilterButton: boolean = false;
    dataFilterVisibility: boolean = false;
    dataFilterObj: any;
    dataFilterIcon: boolean = this.fullValuePigService.addDataFilterIcon;

    elem = document.documentElement;

    languages = [
        { "languageCode": 'en', "languageName": 'English' },
        { "languageCode": 'es', "languageName": 'Spanish' },
        { "languageCode": 'zh', "languageName": 'Chinese' },
        { "languageCode": 'ms', "languageName": 'Malaysian' },
        { "languageCode": 'fr', "languageName": 'French' },
        { "languageCode": 'de', "languageName": 'Germany' },
        { "languageCode": 'nl', "languageName": 'Dutch' },
        { "languageCode": 'pt', "languageName": 'Portugues' },
        { "languageCode": 'ru', "languageName": 'Russian' }
    ]

    constructor(private router: Router, private translateService: TranslateService, private userInputService: UserInputService, private fullValuePigService: FullValuePigService, private _adapter: DateAdapter<any>) {
        translateService.setDefaultLang('en');
    }

    ngOnInit() {
        //this.translateService.currentLang = 'en';
        this.pageTitle = this.pageSummary.pageTitle;
        this.dataZoom = this.pageSummary.dataZoom;
        this.languageSelectDropDown = this.pageSummary.languageSelectDropDown;
        this.backNavigation = this.pageSummary.backNavigation;
        this.dataFilterButton = this.pageSummary.dataFilter;
        this.dataFilterObj = this.pageSummary.dataFilterObj;
        this.dataFilterIcon = this.fullValuePigService.addDataFilterIcon;
    }

    initPageSummaryProperties() {

    }

    reset() {
        this.fullValuePigService.setLoadClientTokenObj(null);
        AttritionViewModel.attritionViewModel = null;
        AttritionLiteViewModel.attritionLiteViewModel = null;
        ProfitOptimizerViewModel.profitOptimizerViewModel = null;
        ProfitOptimizerLiteViewModel.profitOptimizerLiteViewModel = null;
        ProfitFinderViewModel.profitFinderViewModel = null;
        ProfitFinderLiteViewModel.profitFinderLiteViewModel = null;
        PFSession.getInstance().Calculate = null;
        PFLiteSession.getInstance().MatrixExtend = null;
        OMWSession.getInstance().Calculate = null;
        OMWLiteSession.getInstance().MatrixExtend = null;
    }

    redirectToHome() {
        this.fullValuePigService.redirectToLanding();
    }

    switchLang(lang: string) {
        this.translateService.use(lang);

        if (lang == 'zh') {
            this._adapter.setLocale('zh-cn');
            moment.locale('zh-cn')
        }
        else {
            this._adapter.setLocale(lang);
            moment.locale(lang);
        }
    }

    openFullscreen() {
        if (this.elem.requestFullscreen) {
            this.elem.requestFullscreen();
        }
        this.isFullScreen = true;
    }

    closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
        this.isFullScreen = false;
    }

    @HostListener('document:fullscreenchange', ['$event'])
    exitHandler(event) {
        if (!document.fullscreenElement) {
            this.isFullScreen = false;
        }
    }


    dataFilter() {
        this.dataFilterVisibility = true;
        $('#data-filter-modal').modal({ backdrop: 'static', keyboard: false, show: true });
    }

    export() {
        let self = this;
        if (self.homeService.exportImage) {
            self.homeService.exportToImage();
            return;
        }

        let dvID = self.homeService.elementId;
        if (self.export_event_emitter.observers.length != 0) {
            self.export_event_emitter.emit(dvID);
            return;
        }
        let html = "";
        let arrAppendBorder1 = [
            "omwLiteAsMarketedInMatrix", "omwLitePackerMatrix", "asMarketedInMatrix",
            "pfFeedCostByCell", "pfMarketedInMatrix", "profitFinderQuantifyFVPAndNonFVP",
            "profitFinderYPaymentGrid", "pfMOFC", "pfPricePaidByCell", "profitFinderLostOpportunityCost",
            "pfyFeedCostByCell", "pfyPricePaidByCell", "pfyPricePaidByCell", "pfyMOFC",
            "pfyMarketedInMatrix", "profitFinderYQuantifyFVPAndNonfvp", "profitFinderYLostOpportunityCost"
        ];
        let arrAppendBorder2 = [
            "attritioncostsfornonambulatory", "exportDOASummary",
            "exportCullSummaryTab", "exportCullSummaryTabY",
            "exportMortalitySummaryTab", "exportMortalitySummaryTabY"
        ]
        if (arrAppendBorder1.indexOf(dvID) >= 0) {
            html += `<style type="text/css">table {border:solid 1px black;} td {border:solid 1px black;}</style>`
        } else if (arrAppendBorder2.indexOf(dvID) >= 0) {
            html += `<style type="text/css">table {border:solid 1px #72a1d3;} td {border:solid 1px 72a1d3;}</style>`
        }
        html += document.getElementById(dvID).innerHTML;
        self.homeService.exportToExcel(`${dvID}.xls`, html);
    }
}